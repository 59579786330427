import { Fragment, useContext, useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Chip from "@mui/material/Chip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import LeadWorkflow from "./workflow/LeadWorkflow";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { RowProps } from "./LeadList";
import { CCState, IsfpState, OPState } from "../../../../types/cockpit/types";
import { TableSortLabel } from "@mui/material";
import { AuthContext } from "../../../../Contexts";
import { isAdmin, isBankManager } from "../../../../utils/utils";

type Order = "asc" | "desc";
type OrderBy = "name" | "address" | "referrer";

export interface LeadTableProps {
  isLoading: boolean;
  error?: string | string[];
  rows?: RowProps[];
}

export default function LeadTable({ isLoading, error, rows }: LeadTableProps) {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderBy>("name");
  const [sortedRows, setSortedRows] = useState<RowProps[]>([]);

  useEffect(() => {
    if (!rows) return;
    const sorted = [...rows.sort(rowSorter(orderBy, order))];
    setSortedRows(sorted);
  }, [rows, order, orderBy]);

  if (rows && rows?.length < pageSize * page) {
    setPage(0);
    return null;
  }
  const rowSorter = (property: keyof RowProps, order: Order) => {
    return (a: RowProps, b: RowProps) => {
      const inverter = order === "asc" ? 1 : -1;
      const left = a[property];
      const right = b[property];
      if (!left) return 1 * inverter;
      if (!right) return -1 * inverter;
      if (left < right) {
        return -1 * inverter;
      } else if (left > right) {
        return 1 * inverter;
      } else {
        return 0;
      }
    };
  };

  const handleOrderChange = (newOrder: OrderBy) => {
    if (newOrder === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(newOrder);
    }
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = Number.parseInt(event.target.value);
    setPageSize(newPageSize);
    setPage(0);
  };

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement>, page: number) => {
    setPage(page);
  };

  if (isLoading || !rows) {
    return <LoadingData />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }
  if (rows?.length === 0) {
    return <EmptyTable />;
  }
  return (
    <Table aria-label='collapsible table' sx={{ "& .MuiTableCell-root": { border: "none" } }}>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <TableSortLabel
              active={orderBy === "name"}
              direction={order}
              onClick={() => {
                handleOrderChange("name");
              }}
            >
              Name
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "address"}
              direction={order}
              onClick={() => {
                handleOrderChange("address");
              }}
            >
              Adresse
            </TableSortLabel>
          </TableCell>
          {(isBankManager(user) || isAdmin(user)) && (
            <TableCell>
              <TableSortLabel
                active={orderBy === "referrer"}
                direction={order}
                onClick={() => {
                  handleOrderChange("referrer");
                }}
              >
                Berater
              </TableSortLabel>
            </TableCell>
          )}
          <TableCell>Status</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody data-cy='lead-list-table-body'>
        {sortedRows?.slice(page * pageSize, (page + 1) * pageSize).map((row) => <Row key={row.id} row={row} />)}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            onRowsPerPageChange={handlePageSizeChange}
            component='td'
            count={rows?.length || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handlePageChange}
            colSpan={5}
            labelRowsPerPage='Einträge pro Seite'
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}

function LoadingData() {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Stack spacing={2}>
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      </Stack>
    </Box>
  );
}

function LoadingError({ error }: { error: string | string[] }) {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Typography>{error}</Typography>
    </Box>
  );
}

function EmptyTable() {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Typography>Keine Ergebnisse gefunden</Typography>
    </Box>
  );
}

function Row({ row }: { row: RowProps }) {
  const [open, setOpen] = useState(false);
  const { user } = useContext(AuthContext);

  return (
    <Fragment>
      <TableRow
        data-cy={`lead-row-${row.name}`}
        sx={{
          height: 75,
          cursor: "pointer",
          "& .MuiTableCell-root": {
            position: "relative",
            bottom: 0,
            bgcolor: "background.default",
          },
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell padding='checkbox'>
          <Checkbox color='primary' checked={open} />
        </TableCell>
        <TableCell data-cy={`lead-row-name-${row.name}`} component='th' scope='row' sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>
          {row.name}
        </TableCell>
        <TableCell sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>
          <LocationOnIcon sx={{ pr: "0.5rem", verticalAlign: "middle", fontSize: "2rem", color: open ? "primary.main" : "#707070" }} />
          {row.address}
        </TableCell>
        {(isBankManager(user) || isAdmin(user)) && <TableCell sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>{row.referrer}</TableCell>}
        <TableCell sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>
          {row.isfp.state !== IsfpState.START && (
            <Chip sx={{ mr: 1 }} data-cy={`lead-row-isfp-state-chip-${row.name}`} label={IsfpState.toString(row.isfp.state)} />
          )}
          {row.cc.state !== CCState.START && <Chip sx={{ mr: 1 }} data-cy={`lead-row-cc-state-chip-${row.name}`} label={CCState.toString(row.cc.state)} />}
          {row.op.state !== OPState.START && <Chip sx={{ mr: 1 }} data-cy={`lead-row-op-state-chip-${row.name}`} label={OPState.toString(row.op.state)} />}
        </TableCell>
        <TableCell>
          <IconButton data-cy={`lead-row-expand-btn-${row.name}`} aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        data-cy={`lead-row-expanded-${row.name}`}
        sx={{
          borderSpacing: 0,
          bgcolor: "#FBFBFB",
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <LeadWorkflow row={row} />
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow sx={{ height: "1rem" }} />
    </Fragment>
  );
}
