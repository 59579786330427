import Grid from "@mui/material/Unstable_Grid2";
import { SectionProps } from "./ConsumptionCertificateRequestPage";
import NovoSelect from "../../../components/common/NovoSelect";
import { ChangeEvent, Fragment, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

export default function HotWaterSection({ data, disabled, updateHandler }: SectionProps) {
  const [selectedConsumption, setSelectedConsumption] = useState(data.hotWaterData.consumptionType || "");
  const [secondarySelectedConsumption, setSecondarySelectedConsumption] = useState(data.hotWaterData.secondaryConsumptionType || "");

  const consumptionTypeHandler = (value: string) => {
    data.hotWaterData.consumptionType = value;
    data.hotWaterData.hotWaterPercentage = "";
    data.hotWaterData.hotWaterSystem = "";
    data.hotWaterData.readingValue = "";
    data.hotWaterData.readingValueYearBefore = "";
    data.hotWaterData.readingValueTwoYearsBefore = "";
    setSelectedConsumption(value);
    updateHandler(data);
  };

  const secondaryConsumptionTypeHandler = (value: string) => {
    data.hotWaterData.secondaryConsumptionType = value;
    data.hotWaterData.secondaryHotWaterPercentage = "";
    setSecondarySelectedConsumption(value);
    updateHandler(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <NovoSelect
          data-cy='warm-water-consumption-select'
          label='Verbrauch (Primäres Heizsystem)'
          value={data.hotWaterData.consumptionType}
          options={data.hotWaterData.consumptionOptions}
          required={true}
          error={data.hotWaterData.consumptionTypeError}
          disabled={disabled}
          onUpdate={consumptionTypeHandler}
        />
      </Grid>
      {selectedConsumption === data.hotWaterData.consumptionOptions[0] && <HeatingSystemPercentage data={data} updateHandler={updateHandler} />}
      {selectedConsumption === data.hotWaterData.consumptionOptions[2] && <HotWaterReadings data={data} updateHandler={updateHandler} />}
      {data.heatingData.secondaryHeatingSystemType && (
        <Fragment>
          <Grid xs={12}>
            <NovoSelect
              label='Verbrauch (Sekundär Heizsystem)'
              value={data.hotWaterData.secondaryConsumptionType}
              options={data.hotWaterData.secondaryConsumptionOptions}
              required={true}
              error={data.hotWaterData.secondaryConsumptionTypeError}
              disabled={disabled}
              onUpdate={secondaryConsumptionTypeHandler}
            />
          </Grid>
          {secondarySelectedConsumption === data.hotWaterData.secondaryConsumptionOptions[0] && (
            <SecondaryHeatingSystemPercentage data={data} updateHandler={updateHandler} />
          )}
        </Fragment>
      )}
    </Grid>
  );
}

const HeatingSystemPercentage = ({ data, disabled, updateHandler }: SectionProps) => {
  const [hotWaterPercentage, setHotWaterPercentage] = useState(data.hotWaterData.hotWaterPercentage || "");

  const hotWaterPercentageHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setHotWaterPercentage(event.target.value);
    data.hotWaterData.hotWaterPercentage = event.target.value;
    updateHandler(data);
  };

  return (
    <Grid xs={6}>
      <FormControl fullWidth>
        <TextField
          variant='outlined'
          id='anteil-warmwasser-input'
          value={hotWaterPercentage}
          type='number'
          inputProps={{ min: 0, max: 100 }}
          label='Anteil Warmwasser'
          onChange={hotWaterPercentageHandler}
          disabled={disabled}
          required={true}
          error={data.hotWaterData.hotWaterPercentageError}
          InputProps={{
            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
          }}
        />
      </FormControl>
    </Grid>
  );
};

const SecondaryHeatingSystemPercentage = ({ data, disabled, updateHandler }: SectionProps) => {
  const [secondaryHotWaterPercentage, setSecondaryHotWaterPercentage] = useState(data.hotWaterData.secondaryHotWaterPercentage || "");

  const secondaryHotWaterPercentageHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSecondaryHotWaterPercentage(event.target.value);
    data.hotWaterData.secondaryHotWaterPercentage = event.target.value;
    updateHandler(data);
  };

  return (
    <Grid xs={6}>
      <FormControl fullWidth>
        <TextField
          variant='outlined'
          id='secondary-anteil-warmwasser-input'
          value={secondaryHotWaterPercentage}
          type='number'
          inputProps={{ min: 0, max: 100 }}
          label='Anteil Warmwasser (Sekundär Heizsystem)'
          onChange={secondaryHotWaterPercentageHandler}
          disabled={disabled}
          required={true}
          error={data.hotWaterData.secondaryHotWaterPercentageError}
          InputProps={{
            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
          }}
        />
      </FormControl>
    </Grid>
  );
};

const HotWaterReadings = ({ data, disabled, updateHandler }: SectionProps) => {
  const [hotWaterSystem, setHotWaterSystem] = useState(data.hotWaterData.hotWaterSystem || "");
  const [readingValue, setReadingValue] = useState(data.hotWaterData.readingValue || "");
  const [readingValueYearBefore, setReadingValueYearBefore] = useState(data.hotWaterData.readingValueYearBefore || "");
  const [readingValueTwoYearsBefore, setReadingValueTwoYearsBefore] = useState(data.hotWaterData.readingValueTwoYearsBefore || "");

  const hotWaterSystemHandler = (value: string) => {
    setHotWaterSystem(value);
    data.hotWaterData.hotWaterSystem = value;
    updateHandler(data);
  };
  const readingValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReadingValue(event.target.value);
    data.hotWaterData.readingValue = event.target.value;
    updateHandler(data);
  };
  const readingValueYearBeforeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReadingValueYearBefore(event.target.value);
    data.hotWaterData.readingValueYearBefore = event.target.value;
    updateHandler(data);
  };
  const readingValueTwoYearsBeforeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReadingValueTwoYearsBefore(event.target.value);
    data.hotWaterData.readingValueTwoYearsBefore = event.target.value;
    updateHandler(data);
  };

  return (
    <Fragment>
      <Grid xs={12}>
        <NovoSelect
          label='Wasserheizsystem'
          value={hotWaterSystem}
          options={data.hotWaterData.hotWaterSystemOptions}
          required={true}
          error={data.hotWaterData.hotWaterSystemError}
          disabled={disabled}
          onUpdate={hotWaterSystemHandler}
        />
      </Grid>
      <Grid xs={4}>
        <FormControl fullWidth>
          <TextField
            variant='outlined'
            id='wasser-verbrauch-1-input'
            value={readingValue}
            type='number'
            inputProps={{ min: 0 }}
            label='Verbrauch'
            onChange={readingValueHandler}
            disabled={disabled}
            required={true}
            error={data.hotWaterData.readingValueError}
          />
        </FormControl>
      </Grid>
      <Grid xs={4}>
        <FormControl fullWidth>
          <TextField
            variant='outlined'
            id='wasser-verbrauch-2-input'
            value={readingValueYearBefore}
            type='number'
            inputProps={{ min: 0 }}
            label='Verbrauch'
            onChange={readingValueYearBeforeHandler}
            disabled={disabled}
            required={true}
            error={data.hotWaterData.readingValueYearBeforeError}
          />
        </FormControl>
      </Grid>
      <Grid xs={4}>
        <FormControl fullWidth>
          <TextField
            variant='outlined'
            id='wasser-verbrauch-3-input'
            value={readingValueTwoYearsBefore}
            type='number'
            inputProps={{ min: 0 }}
            label='Verbrauch'
            onChange={readingValueTwoYearsBeforeHandler}
            disabled={disabled}
            required={true}
            error={data.hotWaterData.readingValueTwoYearsBeforeError}
          />
        </FormControl>
      </Grid>
    </Fragment>
  );
};
