import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import MetricsList from "./MetricsList";

export default function Metrics() {
  return (
    <Box sx={{ flexGrow: 1, ml: "56px", width: "calc(100% - 56px)" }}>
      <Grid container spacing={2} columnSpacing={{ xs: 2, xl: 4 }} sx={{ mt: 2, maxWidth: "xl", mx: { xs: 0, xl: "auto" } }}>
        <MetricsList />
      </Grid>
    </Box>
  );
}
