import Grid from "@mui/material/Unstable_Grid2";
import { SectionProps } from "./ConsumptionCertificateRequestPage";
import NovoMultiSelect from "../../../components/common/NovoMultiSelect";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";
import { ChangeEvent } from "react";
import RequirementCertificateReferrer from "./RequirementCertificateReferrer";

export default function GeneralSection({ data, disabled, updateHandler }: SectionProps) {
  const ventilationHandler = (value: Array<string>) => {
    data.generalData.ventilationTypes = [...value];
    updateHandler(data);
  };

  const coolingnHandler = (value: Array<string>) => {
    data.generalData.coolingTypes = [...value];
    updateHandler(data);
  };

  const renewablesHandler = (value: Array<string>) => {
    data.generalData.renewableEnergySources = [...value];
    updateHandler(data);
  };

  const handleEmptyChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    event.target.ariaInvalid = checked ? "true" : "false";
    data.generalData.isEmptyProperty = checked;
    updateHandler(data);
  };

  const renovationMeasuresHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.generalData.renovationMeasures = event.target.value;
    updateHandler(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6}>
        <NovoMultiSelect
          data-cy='ventilation-type-select'
          label='Art der Lüftung'
          value={data.generalData.ventilationTypes}
          options={data.generalData.ventilationOptions}
          disabled={disabled}
          onUpdate={ventilationHandler}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <NovoMultiSelect
          data-cy='cooling-type-select'
          label='Kühlung'
          value={data.generalData.coolingTypes}
          options={data.generalData.coolingOptions}
          disabled={disabled}
          onUpdate={coolingnHandler}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <NovoMultiSelect
          data-cy='renewable-energy-select'
          label='Erneuerbare Energien'
          value={data.generalData.renewableEnergySources}
          options={data.generalData.renewableEnergyOptions}
          disabled={disabled}
          onUpdate={renewablesHandler}
        />
      </Grid>
      <Grid xs={12}>
        <FormControl fullWidth>
          <TextField
            variant='outlined'
            id='renovation-measures'
            value={data.generalData.renovationMeasures}
            type='text'
            multiline
            rows={2}
            label='bereits erfolgte Sanierungsmaßnahmen'
            disabled={disabled}
            onChange={renovationMeasuresHandler}
          />
        </FormControl>
        <FormHelperText id='renovation-measures-helper-text'>Fenster gewechselt, Dämmung erhöht, ...</FormHelperText>
      </Grid>
      <Grid xs={12} md={12}>
        <FormControlLabel
          control={<Checkbox required={false} value={data.generalData.isEmptyProperty} onChange={handleEmptyChange} />}
          label='In dem angegebenen Verbrauchszeitraum stand das Objekt mehr als 30% leer.'
          disabled={disabled}
        />
      </Grid>
      {data.generalData.isEmptyProperty && (
        <Grid xs={12} md={12}>
          <RequirementCertificateReferrer
            subject='Neuen%20Bedarfsausweis%20beantragen'
            body='Hallo%20NOVO-Team%2C%0A%0Ada%20meine%20Immobilie%20seit%20einiger%20Zeit%20%C3%BCberwiegend%20leer%20steht%2C%20m%C3%B6chte%20ich%20einen%20Bedarfsnachweis%20beantragen%20und%20ein%20unverbindliches%20Angebot%20einholen.%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen'
          />
        </Grid>
      )}
    </Grid>
  );
}
