import axios from "axios";
import { Property } from "../components/property/Property";
import { apiEndpoint } from "../utils/utils";
import { useContext } from "react";
import { AuthContext } from "../Contexts";

export default function useCreateProperty() {
  const { token } = useContext(AuthContext);

  function createProperty(property: Property) {
    const sendData = async () => {
      await axios.post(`${apiEndpoint()}/properties`, JSON.stringify({ property }), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.id_token}`,
        },
      });
    };
    sendData().catch(console.error);
  }
  return [createProperty];
}
