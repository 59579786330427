import { Cancel, CheckCircle, Construction, Error, HelpOutline, PictureAsPdf, Task, Delete } from "@mui/icons-material";
import { Grid, Icon, IconButton, Stack, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridValidRowModel, deDE } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useFetchConsumptionCertificates } from "../../../hooks/useFetchConsumptionCertificates";
import useDeleteConsumptionCertificate from "../../../hooks/useDeleteConsumptionCertificate";
import { useGetConsumptionCertPdfLink } from "../../../hooks/useGetConsumptionCertPdfLink";
import { ConsumptionCertOrderPaymentStatus, ConsumptionCertificateGenState, ConsumptionCertificateItem } from "../../../types/ConsumptionCertificateState";
import ConsumptionCertificateDialog from "./ConsumptionCertificateDialog";
import { format } from "date-fns";

export type FinalizeCertItem = {
  id: string;
  address: string;
};

type CertificateActions = {
  id: string;
  isPdfAvailable?: boolean;
  state?: string;
  finalizeCertData: FinalizeCertItem;
};

type PurchaseOrder = {
  id?: string;
  status?: ConsumptionCertOrderPaymentStatus;
};

type ConsumptionCertTableEntry = {
  id: string;
  createdAt: number;
  state?: string;
  address?: string;
  lastUpdatedAt: number;
  purchaseOrder?: PurchaseOrder;
  actions: CertificateActions;
};

const convert = (props: ConsumptionCertificateItem[] = []): ConsumptionCertTableEntry[] => {
  if (typeof props === "object" && !Array.isArray(props)) {
    return [];
  }
  return props.map((p): ConsumptionCertTableEntry => {
    return {
      id: p.id,
      createdAt: p.createdAt,
      state: p.state,
      address: p.answers.address,
      lastUpdatedAt: p.lastUpdatedAt,
      purchaseOrder: {
        id: p.purchaseOrder?.id,
        status: p.purchaseOrder?.payment.status,
      },
      actions: {
        id: p.id,
        isPdfAvailable: p.document?.state === ConsumptionCertificateGenState.Ready,
        state: p.state,
        finalizeCertData: {
          id: p.id,
          address: `${p.answers.address ? `${p.answers.address}, ` : ""}${p.answers.postalCode ? `${p.answers.postalCode} - ` : ""}${p.answers.location || ""}`,
        },
      },
    };
  });
};

export default function ConsumptionCertificateList() {
  const { consumptionCerts, isLoading, pageArgs, fetchData, setPageArgs } = useFetchConsumptionCertificates();
  const { getConsumptionCertLink, getDocumentData } = useGetConsumptionCertPdfLink<{ url: string } | undefined>();
  const { deleteConsumptionCertificate, deleting } = useDeleteConsumptionCertificate();

  const intl = useIntl();
  const rows = convert(consumptionCerts);

  const [finalizeCertOpenDialog, setfinalizeCertOpenDialog] = useState(false);
  const [finalizeCertItem, setFinalizeCertItem] = useState<FinalizeCertItem>();

  const finalizeCertificateClick = (finalizeData: FinalizeCertItem) => {
    return () => {
      setFinalizeCertItem(finalizeData);
      setfinalizeCertOpenDialog(true);
    };
  };

  const deleteCertificateClick = (id: string) => {
    return () => {
      deleteConsumptionCertificate(id);
    };
  };

  const dialogCloseHandler = () => {
    setfinalizeCertOpenDialog(false);
    fetchData();
  };

  const openPdfDoc = (certId?: string) => {
    return () => {
      if (!certId) {
        return;
      }
      getConsumptionCertLink(certId);
    };
  };

  useEffect(() => {
    if (getDocumentData) {
      window.location.href = getDocumentData.url;
    }
    if (!deleting) {
      fetchData();
    }
  }, [getDocumentData, deleting, fetchData]);

  const columns: GridColDef[] = [
    {
      field: "id",
      width: 220,
      headerName: "ID",
      valueFormatter: (params) => params.value,
    },
    {
      field: "createdAt",
      width: 160,
      headerName: "Erstellt am",
      valueFormatter: (params) => (params.value ? format(new Date(params.value), "yyyy-MM-dd HH:MM:SS") : "unbekannt"),
      sortComparator: (v1, v2, param1, param2) => {
        if (!param1.value) return 1;
        else if (!param2.value) return -1;
        else return param1.value - param2.value;
      },
    },
    {
      field: "state",
      width: 160,
      headerName: intl.formatMessage({
        id: "app.consumption-certificates-list.state",
      }),
      valueFormatter: (params) =>
        intl.formatMessage({
          id: `app.consumption-certificates-list.state.${params.value}`,
        }),
    },
    {
      field: "address",
      flex: 1,
      headerName: intl.formatMessage({
        id: "app.consumption-certificates-list.address",
      }),
    },
    {
      field: "lastUpdatedAt",
      width: 220,
      headerName: "Zuletzt aktualisiert am",
      valueFormatter: (params) => format(new Date(params.value), "yyyy-MM-dd HH:MM:SS"),
      sortComparator: (v1, v2, param1, param2) => param1.value - param2.value,
    },
    {
      field: "purchaseOrder",
      width: 100,
      align: "center",
      headerName: "Zahlung",
      renderCell: (params: GridCellParams<GridValidRowModel, PurchaseOrder>) => {
        switch (params.value?.status) {
          case ConsumptionCertOrderPaymentStatus.Canceled:
            return (
              <Icon title='Zahlgang abgebrochen'>
                <Cancel />
              </Icon>
            );
          case ConsumptionCertOrderPaymentStatus.Failed:
            return (
              <Icon color='warning' title='Zahlung fehlgeschlagen'>
                <Error />
              </Icon>
            );
          case ConsumptionCertOrderPaymentStatus.Paid:
            return (
              <Icon color='success' title='Kunde hat bezahlt'>
                <CheckCircle />
              </Icon>
            );
          case ConsumptionCertOrderPaymentStatus.NoPaymentRequired:
            return (
              <Icon color='success' title='Bezahlt via Voucher'>
                <CheckCircle />
              </Icon>
            );
          default:
            return (
              <Icon title='Noch keine Info'>
                <HelpOutline />
              </Icon>
            );
        }
      },
    },

    {
      field: "actions",
      width: 180,
      headerAlign: "center",
      align: "center",
      headerName: intl.formatMessage({
        id: "app.consumption-certificates-list.actions",
      }),
      renderCell: (params: GridCellParams<GridValidRowModel, CertificateActions>) => {
        if (!params.value) {
          return <></>;
        }

        return (
          <>
            <IconButton
              color='primary'
              title={intl.formatMessage({ id: "app.consumption-certificates-list.actions.show-pdf" })}
              disabled={!params.value.isPdfAvailable}
              onClick={openPdfDoc(params.value.id)}
            >
              <PictureAsPdf />
            </IconButton>
            <IconButton
              color='primary'
              component={Link}
              disabled={params.value.state === "final"}
              title={intl.formatMessage({ id: "app.consumption-certificates-list.actions.modernisation-recommendations" })}
              to={`${params.value.id}/modernisation-recommendations`}
            >
              <Construction />
            </IconButton>
            <IconButton
              color='primary'
              disabled={params.value.state === "final"}
              title={"Verbrauchsausweis generieren und hochladen"}
              onClick={finalizeCertificateClick(params.value.finalizeCertData)}
            >
              <Task />
            </IconButton>
            <IconButton
              color='primary'
              disabled={params.value.state === "final"}
              title={"Verbrauchsnachweis löschen"}
              onClick={deleteCertificateClick(params.value.id)}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Grid container alignItems='center' justifyContent='center' spacing={0} direction='column' sx={{ minHeight: "100vh" }}>
      <Stack spacing={2} style={{ padding: 20, width: "100%", minHeight: "100vh" }}>
        <Typography variant='h4' align='left'>
          Verbrauchausweis
        </Typography>
        <DataGrid
          loading={isLoading}
          autoPageSize
          rows={rows}
          columns={columns}
          initialState={{
            pagination: { paginationModel: pageArgs },
            sorting: {
              sortModel: [{ field: "lastUpdatedAt", sort: "desc" }],
            },
          }}
          onPaginationModelChange={setPageArgs}
          pageSizeOptions={[5, 10, 25]}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        />
      </Stack>
      {finalizeCertOpenDialog && (
        <ConsumptionCertificateDialog isOpen={finalizeCertOpenDialog} closeHandler={dialogCloseHandler} certificate={finalizeCertItem} />
      )}
    </Grid>
  );
}
