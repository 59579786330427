import { createTheme } from "@mui/material/styles";
import { Themes } from "../types/types";
import { deDE } from "@mui/material/locale";

const novo = createTheme(
  {
    name: Themes.NOVO,
    logo: "NOVO-TYP-WBG-S.png",
    logoClass: "App-logo",
    palette: {
      mode: "light",
      primary: {
        main: "#FFDE59",
      },
      secondary: {
        main: "#333333",
      },
      success: {
        main: "#00AC4F",
      },
      error: {
        main: "#D0004B",
      },
      background: {
        default: "#ffffff",
        paper: "#fdfdfd",
      },
      text: {
        primary: "rgba(0,0,0,0.87)",
        secondary: "rgba(0,0,0,0.6)",
        disabled: "rgba(0,0,0,0.38)",
      },
    },
    typography: {
      fontFamily: [
        "Montserrat",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 800,
    },
  },
  deDE,
);

export default novo;
