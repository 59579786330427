import { apiEndpoint } from "../utils/utils";
import { useAuthAxios } from "./useAuthAxios";

export default function useDeleteConsumptionCertificate() {
  const { authAxios, running } = useAuthAxios();

  function deleteConsumptionCertificate(id: string) {
    const sendData = async () => {
      await authAxios({
        method: "DELETE",
        baseURL: `${apiEndpoint()}/consumption-certificate/${id}`,
      });
    };
    sendData().catch(console.error);
  }
  return { deleteConsumptionCertificate, deleting: running };
}
