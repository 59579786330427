import axios from "axios";
import { apiEndpoint } from "../utils/utils";
import { Property } from "../types/types";
import { AuthContext } from "../Contexts";
import { useContext } from "react";

const API_ENDPOINT = `${apiEndpoint()}/properties/`;

export default function useDeleteProperty() {
  const { token, logout } = useContext(AuthContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deleteProperty = async (id: any) => {
    try {
      await axios.delete<Property>(API_ENDPOINT + id, {
        headers: {
          Authorization: `Bearer ${token?.id_token}`,
        },
      });
    } catch (e) {
      if (e.response.status === 401) {
        logout();
      }
    }
  };

  return deleteProperty;
}
