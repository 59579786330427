import { useCallback } from "react";
import { apiEndpoint } from "../utils/utils";
import { useAuthAxios } from "./useAuthAxios";
import { Lead } from "../types/cockpit/types";

export const API_ENDPOINT = `${apiEndpoint()}/leads`;

export default function useFetchLeads() {
  const { authAxios, error, running, data } = useAuthAxios<Lead[]>();

  const getLeads = useCallback(
    async () =>
      await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}`,
      }),
    [authAxios],
  );

  return { getLeads, error, isLoading: running, leads: data };
}
