import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { useState } from "react";
import { AuthError, cognitoClientId, cognitoPoolId } from "./useAuth";

const userPool = new CognitoUserPool({ ClientId: cognitoClientId(), UserPoolId: cognitoPoolId() });

export function useResendConfirmationCode() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | undefined>();
  const [resendSuccess, setResendSuccessful] = useState<{ username: string } | undefined>(undefined);

  const resendConfirmationCode = async (email: string) => {
    setIsLoading(true);
    setResendSuccessful(undefined);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: email });
    await new Promise((resolve, reject) => {
      user.resendConfirmationCode((err, success) => {
        if (err) {
          return reject(err);
        }
        setResendSuccessful({ username: email });
        return resolve(success);
      });
    })
      .catch((err) => {
        console.log(JSON.stringify(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return { isLoading, resendSuccess, error, resendConfirmationCode };
}
