import { ChangeEvent, Fragment } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import FormControl from "@mui/material/FormControl";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import NovoSelect from "../../../components/common/NovoSelect";
import { SectionProps } from "./ConsumptionCertificateRequestPage";
import NovoImageUpload from "../../../components/common/NovoImageUpload";
import { Partition, Reforms } from "./ConsumptionCertificateRequestEnums";
import HelpIcon from "@mui/icons-material/Help";
import { NovoTooltip } from "../../../components/common/NovoTooltip";
import RequirementCertificateReferrer from "./RequirementCertificateReferrer";

export default function BuildingSection({ data, updateHandler }: SectionProps) {
  const photoHandler = (value?: File) => {
    data.buildingData.photo = value;
    updateHandler(data);
  };
  const purposeHandler = (value: string) => {
    data.buildingData.purpose = value;
    updateHandler(data);
  };

  const typeHandler = (value: string) => {
    data.buildingData.type = value;
    updateHandler(data);
  };

  const reformHandler = (value: string) => {
    data.buildingData.reform = value;
    updateHandler(data);
  };

  const partitionHandler = (value: string) => {
    data.buildingData.partition = value;
    updateHandler(data);
  };

  const houseCountHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.buildingData.houseCount = event.target.value;
    updateHandler(data);
  };

  const constructionYearHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.buildingData.constructionYear = event.target.value;
    updateHandler(data);
  };

  const surfaceHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.buildingData.surface = event.target.value;
    updateHandler(data);
  };

  const addressHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.buildingData.address = event.target.value;
    updateHandler(data);
  };

  const postalCodeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const postalCode = event.target.value;
    data.buildingData.postalCode = postalCode;
    const mustVerifyPLZ = /^[0-9]{5}$/.test(postalCode);
    updateHandler(data, mustVerifyPLZ);
  };

  const townHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.buildingData.town = event.target.value;
    updateHandler(data);
  };

  const stateHandler = (value: string) => {
    data.buildingData.state = value;
    updateHandler(data);
  };

  const generatePLZHelperText = () => {
    let plzHelperText = "";
    if (data.buildingData.postalCodeError) {
      plzHelperText = "5-stellig, z.B. 10115";
    } else if (data.buildingData.postalCodeUnknown) {
      plzHelperText = "Unbekannte Postleitzahl";
    }
    return plzHelperText;
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <NovoImageUpload onChange={photoHandler} />
      </Grid>
      <Grid xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            variant='outlined'
            data-cy='build-year-input'
            id='build-year-input'
            value={data.buildingData.constructionYear}
            type='number'
            inputProps={{ min: 1700, max: data.buildingData.maxConstructionYear }}
            label='Baujahr'
            onChange={constructionYearHandler}
            required={true}
            error={data.buildingData.constructionYearError}
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={6}>
        <NovoSelect
          data-cy='reason-select'
          label='Anlass'
          value={data.buildingData.purpose}
          options={data.buildingData.purposeOptions}
          onUpdate={purposeHandler}
          required={true}
          error={data.buildingData.purposeError}
        />
      </Grid>
      {+data.buildingData.constructionYear > data.buildingData.maxConstructionYear && (
        <Grid xs={12} md={12}>
          <RequirementCertificateReferrer
            description={`Verbrauchsausweise können nur für Gebäude erstellt werden, die älter als ${data.buildingData.maxConstructionYear} sind. Kontaktieren Sie uns per E-Mail und wir unterstützen Sie bei den nächsten Schritten.`}
            subject='Neuen%20Bedarfsausweis%20beantragen'
            body='Hallo%20NOVO-Team%2C%0A%0Ada%20mir%20nicht%20gen%C3%BCgend%20Verbrauchsdaten%20vorliegen%2C%20m%C3%B6chte%20ich%20einen%20Bedarfsausweis%20anfordern%20und%20ein%20unverbindliches%20Angebot%20einholen.%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen'
          />
        </Grid>
      )}
      {data.buildingData.constructionYear.length > 3 && +data.buildingData.constructionYear < 1977 && (
        <Grid xs={12} md={12}>
          <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
            <NovoSelect
              label='Wärmeschutzverordnung'
              value={data.buildingData.reform}
              options={data.buildingData.reformOptions}
              onUpdate={reformHandler}
              required={true}
              error={data.buildingData.reformError}
            />
            <NovoTooltip
              title={
                <Fragment>
                  <Typography variant='subtitle1' style={{ fontWeight: 400 }}>
                    Die Anforderung gilt als erfüllt (alle Punkte müssen erfüllt werden), wenn:
                  </Typography>
                  <Typography variant='body2'>
                    <ol>
                      <li>
                        für den Großteil der Außenwände eine der folgenden Konstruktionen vorhanden ist:
                        <ul>
                          <li>24cm Bimsstein-Mauerwerk, Porenbeton oder Blocksteine ODER</li>
                          <li>38cm Kalksanstein-Mauerwerk mit mind. 6cm äußerer Dämmung ODER</li>
                          <li>Zweischaliges Mauerwerk mit 8cm Dämmung</li>
                        </ul>
                      </li>
                      <li>Ihre Fenster Holz- oder Kunststofffenster mit Doppel- oder Isolierverglasung sind</li>
                      <li>das Dach gedämmt ist (mind. 18cm Aufsparren-, 15cm Zwischensparrendämmung oder 8cm Dachbodendämmung)</li>
                      <li>
                        bei einem unbeheizten Keller 5,5cm Kellerdeckendämmung vorhanden sind (bei beheizten Kellern oder keinem Keller ist keine Dämmung
                        notwendig).
                      </li>
                    </ol>
                    Sind alle Kategorien erfüllt, können Sie „Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten“ auswählen. Wir fragen nach den
                    Informationen, um Sie in die richtige Kategorie des Verbrauchs- oder Bedarfsausweises einordnen zu können.
                  </Typography>
                </Fragment>
              }
            >
              <HelpIcon />
            </NovoTooltip>
          </Stack>
        </Grid>
      )}
      {data.buildingData.constructionYear.length > 3 &&
        +data.buildingData.constructionYear < 1977 &&
        data.buildingData.reform !== Reforms["Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten"] && (
          <Grid xs={12} md={12}>
            <RequirementCertificateReferrer
              subject='Neuen%20Bedarfsausweis%20beantragen'
              body='Hallo%20Team%20NOVO%2C%20%0A%0Ada%20meine%20Immobilie%20die%20Anforderungen%20der%20W%C3%A4rmeschutzverordnung%20von%201977%20wahrscheinlich%20nicht%20erf%C3%BCllt%2C%20m%C3%B6chte%20ich%20einen%20Bedarfsausweis%20anfragen%20und%20bitte%20um%20ein%20unverbindliches%20Angebot.%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen'
            />
          </Grid>
        )}
      <Grid xs={12} md={6}>
        <NovoSelect
          data-cy='construction-type-select'
          label='Gebäudetyp'
          value={data.buildingData.type}
          options={data.buildingData.typeOptions}
          onUpdate={typeHandler}
          required={true}
          error={data.buildingData.typeError}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            variant='outlined'
            id='anzahl-input'
            data-cy='division-count-input'
            value={data.buildingData.houseCount}
            type='number'
            inputProps={{ min: 0 }}
            label='Anzahl Wohnungen'
            onChange={houseCountHandler}
            required={true}
            error={data.buildingData.houseCountError}
          />
        </FormControl>
      </Grid>
      {+data.buildingData.houseCount > 4 && (
        <Grid xs={12} md={12}>
          <RequirementCertificateReferrer
            description='Sie benötigen einen Verbauchsausweis für ein Gebäude mit mehr als 4 Wohneinheiten? Dann schreiben Sie uns gerne eine Email und wir melden uns bei Ihnen zurück! '
            subject='Neuen%20Bedarfsausweis%20beantragen'
            body='Hallo%20Team%20NOVO%2C%20%0A%0Ada%20meine%20Immobilie%20mehr%20als%204%20Wohnungen%20hat%2C%20m%C3%B6chte%20ich%20einen%20Bedarfsausweis%20anfragen%20und%20bitte%20um%20ein%20unverbindliches%20Angebot.%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen'
          />
        </Grid>
      )}
      <Grid xs={12} md={6}>
        <NovoSelect
          data-cy='construction-section-select'
          label='Gebäudeteil'
          value={data.buildingData.partition}
          options={data.buildingData.partitionOptions}
          onUpdate={partitionHandler}
          required={true}
          error={data.buildingData.partitionError}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            data-cy='sq-meters-input'
            variant='outlined'
            id='wohnflache-input'
            value={data.buildingData.surface}
            type='number'
            inputProps={{ min: 0 }}
            label='Wohnfläche'
            onChange={surfaceHandler}
            required={true}
            error={data.buildingData.surfaceError}
            InputProps={{
              endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
            }}
          />
        </FormControl>
      </Grid>
      {data.buildingData.partition === Partition["Teil des Nichtwohngebäudes"] && (
        <Grid xs={12} md={12}>
          <RequirementCertificateReferrer subject='Teil%20des%20Nichtwohngeb%C3%A4udes%20certificat' />
        </Grid>
      )}
      <Grid xs={12}>
        <FormControl fullWidth>
          <TextField
            data-cy='street-address-input'
            variant='outlined'
            id='strasse-input'
            value={data.buildingData.address}
            label='Straße & Hausnummer'
            onChange={addressHandler}
            required={true}
            error={data.buildingData.addressError}
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={2}>
        <FormControl fullWidth>
          <TextField
            data-cy='plz-input'
            variant='outlined'
            id='plz-input'
            value={data.buildingData.postalCode}
            type='number'
            inputProps={{ pattern: /^[0-9]{5}$/ }}
            label='PLZ'
            onChange={postalCodeHandler}
            required={true}
            error={data.buildingData.postalCodeError || data.buildingData.postalCodeUnknown}
            helperText={generatePLZHelperText()}
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            data-cy='city-input'
            variant='outlined'
            id='ort-input'
            value={data.buildingData.town}
            label='Ort'
            onChange={townHandler}
            required={true}
            error={data.buildingData.townError}
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={4}>
        <NovoSelect
          data-cy='country-state-select'
          label='Bundesland'
          value={data.buildingData.state}
          options={data.buildingData.stateOptions}
          onUpdate={stateHandler}
          required={true}
          error={data.buildingData.stateError}
        />
      </Grid>
    </Grid>
  );
}
