import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ChangeEvent, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Sophia from "./Sophia";
import ProgressBar from "./ProgressBar";
import { ScreenProps } from "./SchnellcheckFunnel";
import { HeatingSystemType } from "../../../../types/Schnellcheck";
import { allHeatingTypes, getHeatingLabel } from "./utils";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import { Steps } from "../../../../types/cockpit/types";
import usePutMetric from "../../../../hooks/usePutMetric";

export default function Screen2({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, saveAndNext }: ScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [putMetric] = usePutMetric();

  useEffect(() => {
    putMetric("Schnellcheck", "RenderHeatingPage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const conditionalRendering = () => {
    if (isMobile) {
      return;
    }
    return (
      <>
        <Grid sx={{ px: 0 }}>
          <Divider orientation='vertical' />
        </Grid>
        <Grid sx={{ flexGrow: 1 }}>
          <Sophia step={Steps.heating} />
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        backgroundColor: "#f6f6f6",
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid xs={12}>
        <ProgressBar label={"20% geschafft"} value={20} />
      </Grid>
      <Grid xs={12} lg={8}>
        <Form schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={isUpdateRunning} prev={prev} saveAndNext={saveAndNext} />
      </Grid>
      {conditionalRendering()}
    </Grid>
  );
}

function Form({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, saveAndNext }: ScreenProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);
  const [heatingError, setHeatingError] = useState<string>();
  const [yearError, setYearError] = useState<string>();

  const buildingYear = schnellcheck?.answers?.houseDetails?.yearOfConstruction || 1800;
  const currentYear = new Date().getFullYear();

  const updateHeatingSystem = (value: HeatingSystemType) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseHeating: {
          ...schnellcheck.answers?.houseHeating,
          type: value,
        },
      },
    });
    setHeatingError(undefined);
  };

  const updateInstallationYear = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const yearOfInstalation = /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseHeating: {
          ...schnellcheck.answers?.houseHeating,
          yearOfInstalation,
        },
      },
    });
    if (yearOfInstalation) {
      setYearError(undefined);
    }
  };

  const validate = () => {
    const { yearOfInstalation, type } = schnellcheck.answers?.houseHeating ?? {};
    if (!type) {
      setHeatingError("Pflichtfeld");
      return false;
    } else {
      setHeatingError(undefined);
    }

    if (!yearOfInstalation) {
      setYearError("Pflichtfeld");
      return false;
    } else if (yearOfInstalation < buildingYear || yearOfInstalation > currentYear) {
      setYearError(`Das Jahr muss zwischen ${buildingYear} und ${currentYear} liegen`);
      return false;
    } else {
      setYearError(undefined);
    }
    return true;
  };

  const renderDialogLink = () => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    if (isDesktop) {
      return;
    }
    return (
      <Grid xs={12} lg={0} sx={{ mt: 2 }}>
        <Link
          variant='caption'
          onClick={toggleOpen}
          sx={{
            color: "text.primary",
            textDecorationColor: "rgba(0, 0, 0, 0.87)",
            fontWeight: "fontWeightMedium",
          }}
        >
          Warum benötigen wir diese Daten?
        </Link>
        <Dialog open={open} onClose={toggleOpen} fullScreen>
          <DialogTitle>
            <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sophia step={Steps.heating} />
          </DialogContent>
        </Dialog>
      </Grid>
    );
  };

  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
      sx={{
        ml: { lg: 4 },
        px: { xs: 2 },
        mx: { xs: 0, lg: "inherit" },
      }}
    >
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant='body1'>
          <strong>Schritt 2: </strong>Informationen zum Heizungsystem
        </Typography>
      </Grid>
      <Grid xs={12}>
        <FormControl required error={!!heatingError}>
          <Grid
            container
            spacing={{
              xs: 2,
              lg: 4,
            }}
            sx={{
              my: -2,
              borderRadius: 1,
              borderWidth: heatingError ? 1 : 0,
              borderStyle: heatingError ? "solid" : "",
              borderColor: "error.main",
            }}
          >
            {allHeatingTypes().map((system) => (
              <Grid key={system} xs={6} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={`checkbox-${system}`}
                      sx={{ "&.Mui-checked": { color: "text.primary" } }}
                      id={`${system}-heating-system-option`}
                      checked={schnellcheck.answers?.houseHeating?.type === system}
                      data-cy={`${system}-heating-system-option`}
                    />
                  }
                  label={getHeatingLabel(system)}
                  onChange={() => updateHeatingSystem(system)}
                />
              </Grid>
            ))}
            <Grid xs={12} sx={{ mt: { xs: -1, lg: -2 } }}>
              {heatingError && <FormHelperText>{heatingError}</FormHelperText>}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <Grid xs={12}>
        <FormControl sx={{ "& > div > div.MuiInputBase-root": { backgroundColor: "#FFFFFF" }, width: "100%" }}>
          <TextField
            variant='outlined'
            id='installation-year-input'
            data-cy='installation-year-input'
            value={schnellcheck.answers?.houseHeating?.yearOfInstalation}
            label='Einbaujahr'
            onChange={updateInstallationYear}
            required={true}
            type='number'
            inputProps={{ min: buildingYear, max: currentYear }}
            error={!!yearError}
            helperText={yearError}
            InputLabelProps={{ shrink: !!schnellcheck.answers?.houseHeating?.yearOfInstalation }}
          />
        </FormControl>
      </Grid>
      {renderDialogLink()}
      <Grid xs={12} sx={{ mt: { xs: 2, lg: 8 } }}>
        <Stack direction={"row"} alignItems={"flex-start"}>
          <Button variant='text' color='secondary' onClick={prev}>
            ZURÜCK
          </Button>
          <Box sx={{ flexGrow: 1 }}>
            <ButtonWithSpinner
              variant='contained'
              sx={{ float: "inline-end" }}
              loading={isUpdateRunning}
              data-cy='screen-2-next-btn'
              onClick={() => {
                if (validate()) {
                  saveAndNext();
                }
              }}
              label={"WEITER"}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}
