import { useCallback } from "react";
import { EventType, ConsultantMetrics } from "../types/ConsultantMetrics";
import { apiEndpoint } from "../utils/utils";
import { useAuthAxios } from "./useAuthAxios";

const API_ENDPOINT = `${apiEndpoint()}/metrics/consultant/leads`;

export type MetricRangeParams = { [rangeName: string]: { from: number; to: number } };

export type MetricCalcOptions = { deduplicate?: "newest" | "oldest"; events?: EventType[] };

export default function useFetchConsultantMetrics() {
  const { authAxios, error, running, data } = useAuthAxios<ConsultantMetrics>();

  const getConsultantMetrics = useCallback(
    async (metricRanges?: MetricRangeParams, opts?: MetricCalcOptions) => {
      return await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}`,
        params: {
          ...(metricRanges ? { ranges: encodeURIComponent(JSON.stringify(metricRanges)) } : {}),
          ...(opts ? { options: encodeURIComponent(JSON.stringify(opts)) } : {}),
        },
      });
    },
    [authAxios],
  );

  return { getConsultantMetrics, error, isLoading: running, metrics: data };
}
