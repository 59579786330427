import { useCallback } from "react";
import { ConsultantWithMetrics } from "../types/cockpit/types";
import { apiEndpoint } from "../utils/utils";
import { useAuthAxios } from "./useAuthAxios";

const API_ENDPOINT = `${apiEndpoint()}/consultants`;

export function useFetchConsultants() {
  const { authAxios, error, running, data } = useAuthAxios<ConsultantWithMetrics[]>();

  const getConsultants = useCallback(
    async () =>
      await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}`,
      }),
    [authAxios],
  );

  return { getConsultants, error, isLoading: running, consultants: data };
}
