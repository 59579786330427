import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";

interface StepperRefs {
  buildingSectionRef: React.MutableRefObject<HTMLDivElement | HTMLInputElement | null>;
  generalSectionRef: React.MutableRefObject<HTMLDivElement | HTMLInputElement | null>;
  heatingSectionRef: React.MutableRefObject<HTMLDivElement | HTMLInputElement | null>;
  hotWaterSectionRef: React.MutableRefObject<HTMLDivElement | HTMLInputElement | null>;
}
export default function Stepper({ steps, refs }: { steps: Array<string>; refs: StepperRefs }) {
  const [step, setStep] = useState(0);
  const [top, setTop] = useState(0);
  const stepRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onScroll = (e: Event) => {
      if (!e || !e.target || !(e.target instanceof Document)) {
        return;
      }
      const buildingSectionTop = refs.buildingSectionRef.current?.getBoundingClientRect().top || 3000;
      const generalSectionTop = refs.generalSectionRef.current?.getBoundingClientRect().top || 3000;
      const heatingSectionTop = refs.heatingSectionRef.current?.getBoundingClientRect().top || 3000;
      const hotWaterSectionTop = refs.hotWaterSectionRef.current?.getBoundingClientRect().top || 3000;
      const horizon = Math.round(window.innerHeight / 2);
      const isAtBottom = document.documentElement.scrollHeight === window.scrollY + window.innerHeight;
      if (isAtBottom || hotWaterSectionTop < horizon) {
        setTop(Math.max(hotWaterSectionTop, 10));
        if (step !== 3) setStep(3);
      } else if (heatingSectionTop < horizon) {
        setTop(Math.max(heatingSectionTop, 10));
        if (step !== 2) setStep(2);
      } else if (generalSectionTop < horizon) {
        setTop(Math.max(generalSectionTop, 10));
        if (step !== 1) setStep(1);
      } else {
        setTop(Math.max(buildingSectionTop, 10));
        if (step !== 0) setStep(0);
      }
    };
    window.addEventListener("scroll", onScroll);
    if (top === 0) {
      const buildingSectionTop = refs.buildingSectionRef.current?.getBoundingClientRect().top || 3000;
      setTop(buildingSectionTop);
    }
    return () => window.removeEventListener("scroll", onScroll);
  }, [refs, step, top]);

  const styling = {
    position: "fixed",
    top: `${top}px`,
    display: top === 0 ? "none" : "block",
    transition: "top 400ms ease-out",
  };

  return (
    <Box sx={styling} p={1}>
      {steps.map((section: string) => {
        const key = steps.indexOf(section);
        if (key === step) {
          return (
            <Typography key={key} variant='h4' ref={stepRef} gutterBottom>
              {section}
            </Typography>
          );
        }
        return (
          <Typography key={key} variant='subtitle2' gutterBottom>
            {section}
          </Typography>
        );
      })}
    </Box>
  );
}
