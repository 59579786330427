import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { stripeKey, apiEndpoint } from "../../../utils/utils";

const stripePromise = loadStripe(stripeKey());

export default function StripeOrderPaymentPage() {
  const [clientSecret, setClientSecret] = useState("");
  const params = useParams();
  const [searchParams] = useSearchParams();

  const state = searchParams.get("state");

  useEffect(() => {
    if (params.orderId) {
      fetch(`${apiEndpoint()}/orders/${params.orderId}/payments/stripe${state ? `?state=${state}` : ""}`, {
        method: "POST",
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.stripeClientSecret));
    }
  }, [params.orderId, searchParams, state]);

  const options = { clientSecret };

  return (
    <div id='checkout'>
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
}
