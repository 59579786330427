import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { KPIs } from "./Kpis";
import Shortcuts from "./Shortcuts";
import LeadList from "./leadlist/LeadList";
import { useContext } from "react";
import { AuthContext } from "../../../Contexts";
import RevenueEstimate from "./RevenueEstimate";

export default function Leads() {
  const { user } = useContext(AuthContext);

  return (
    <Box
      sx={{
        flexGrow: 1,
        ml: "56px",
        width: "calc(100% - 56px)",
      }}
    >
      <Grid
        container
        spacing={2}
        columnSpacing={{
          xs: 0,
          lg: 4,
        }}
        sx={{
          mt: 2,
          maxWidth: "xl",
          mx: { xs: 0, xl: "auto" },
        }}
      >
        <Grid container xs={12} lg={8}>
          <Grid xs={12}>
            <KPIs />
          </Grid>
          <Grid xs={12}>
            <RevenueEstimate />
          </Grid>
        </Grid>
        <Grid container xs={12} lg={4}>
          <Grid xs={12}>
            <Shortcuts consultantCode={user?.shortLink} />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <LeadList />
        </Grid>
      </Grid>
    </Box>
  );
}
