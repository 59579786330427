import { ConsumptionCertificateItem } from "../ConsumptionCertificateState";
import { SchnellcheckQuestionnaire } from "../Schnellcheck";

export interface ConsultantWithMetrics {
  name: string;
  email: string;
  leadCount: number;
  invites: number;
  invitesAccepted: number;
  phoneNumber: string;
  lastLoggedInOn?: number;
  registeredOn?: number;
}

export interface CreateLeadRequest {
  name: string;
  email: string;
  cc: boolean;
  referrerProduct: ProductRequest;
}

export interface ProductRequest {
  product: Product;
  isFree: boolean;
  createdAt?: number;
}

export interface InviteEmailTemplate {
  emailMessage: string;
}

export enum Product {
  onePager = "onePager",
  isfp = "isfp",
  consumptionCertificate = "consumptionCertificate",
}

export enum CCState {
  START,
  INVITED,
  SUBMITTED,
  FINAL,
}

export enum Steps {
  start = "",
  heating = "#heating",
  insulation = "#insulation",
  openings = "#openings",
  report = "#report",
  confirmation = "#confirmation",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CCState {
  export function toString(state: CCState): string {
    switch (state) {
      case CCState.START:
        return "zum Energieausweis einladen";
      case CCState.INVITED:
        return "zum Energieausweis eingeladen";
      case CCState.SUBMITTED:
        return "Einladung angenommen";
      case CCState.FINAL:
        return "Energieausweis bereitgestellt";
    }
  }

  export function fromString(stateName: string): CCState | undefined {
    switch (stateName) {
      case "zum Energieausweis einladen":
        return CCState.START;
      case "zum Energieausweis eingeladen":
        return CCState.INVITED;
      case "Einladung angenommen":
        return CCState.SUBMITTED;
      case "Energieausweis bereitgestellt":
        return CCState.FINAL;
      default:
        return undefined;
    }
  }

  export function all(): Array<CCState> {
    return [CCState.START, CCState.INVITED, CCState.SUBMITTED, CCState.FINAL];
  }
}

export enum IsfpState {
  START,
  INVITED,
  STATUS_QUO,
  OFFER_REQUESTED,
  OFFER_ACCEPTED,
  RENOVATIONS,
  FINAL,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace IsfpState {
  export function toString(state: IsfpState): string {
    switch (state) {
      case IsfpState.START:
        return "zum Schnellcheck einladen";
      case IsfpState.INVITED:
        return "zum Schnellcheck eingeladen";
      case IsfpState.STATUS_QUO:
        return "Schnellcheck durchgeführt";
      case IsfpState.OFFER_REQUESTED:
        return "iSFP-Angebot angefragt";
      case IsfpState.OFFER_ACCEPTED:
        return "iSFP beauftragt";
      case IsfpState.RENOVATIONS:
        return "Dateneingabe für iSFP erfolgt";
      case IsfpState.FINAL:
        return "iSFP bereitgestellt";
    }
  }

  export function fromString(stateName: string): IsfpState | undefined {
    switch (stateName) {
      case "zum Schnellcheck einladen":
        return IsfpState.START;
      case "zum Schnellcheck eingeladen":
        return IsfpState.INVITED;
      case "Schnellcheck durchgeführt":
        return IsfpState.STATUS_QUO;
      case "iSFP-Angebot angefragt":
        return IsfpState.OFFER_REQUESTED;
      case "iSFP beauftragt":
        return IsfpState.OFFER_ACCEPTED;
      case "Dateneingabe für iSFP erfolgt":
        return IsfpState.RENOVATIONS;
      case "iSFP bereitgestellt":
        return IsfpState.FINAL;
      default:
        return undefined;
    }
  }

  export function all(): Array<IsfpState> {
    return [
      IsfpState.START,
      IsfpState.INVITED,
      IsfpState.STATUS_QUO,
      IsfpState.OFFER_REQUESTED,
      IsfpState.OFFER_ACCEPTED,
      IsfpState.RENOVATIONS,
      IsfpState.FINAL,
    ];
  }
}

export enum OPState {
  START,
  INVITED,
  FINAL,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OPState {
  export function toString(state: OPState): string {
    switch (state) {
      case OPState.START:
        return "zum Sanierungsgutachten einladen";
      case OPState.INVITED:
        return "zum Sanierungsgutachten eingeladen";
      case OPState.FINAL:
        return "Sanierungsgutachten bereitgestellt";
    }
  }

  export function fromString(stateName: string): OPState | undefined {
    switch (stateName) {
      case "zum Sanierungsgutachten einladen":
        return OPState.START;
      case "zum Sanierungsgutachten eingeladen":
        return OPState.INVITED;
      case "Sanierungsgutachten bereitgestellt":
        return OPState.FINAL;
      default:
        return undefined;
    }
  }

  export function all(): Array<OPState> {
    return [OPState.START, OPState.INVITED, OPState.FINAL];
  }
}

export interface Lead {
  id: string;
  bubbleId?: string;
  name: string;
  email: string;
  productInvites?: ProductRequest[];
  referrer?: string;
  tenant?: string;
  consultantDataReviewConsent?: boolean;
  properties?: Property[];
  createdAt?: number;
}

export interface Property {
  isfp?: Isfp;
  consumptionCertificate?: ConsumptionCertificateItem;
}

export interface BubbleFlow {
  renovationResponse?: RenovationResponse;
  statusQuoResponse?: StatusQuoResponse;
  offerRequest?: OfferRequest;
  renovationReport?: RenovationReport;
}

export interface Isfp {
  // @deprecated BubbleFlow is here for old compatibility reasons,
  //             no new development will be supported
  bubbleFlow?: BubbleFlow;

  schnellcheck?: SchnellcheckQuestionnaire;
}

export interface RenovationResponse {
  airVents?: string[];
  atticHeated?: string;
  basementHeated?: string;
  bufferTankSize?: number;
  damages?: string;
  exteriorWalls?: string[];
  floor?: string;
  heatGenerator?: string;
  heaterBufferTank?: string;
  heaterHydraulicallyBalanced?: string;
  hotWaterCirculationPump?: string;
  housePhotoBackLeft?: string;
  housePhotoBackRight?: string;
  housePhotoFrontLeft?: string;
  housePhotoFrontRight?: string;
  isCompleted?: boolean;
  windowFrame?: string;
  windowGlazing?: string;
  energyConsumptionStatement1?: string;
  energyConsumptionStatement2?: string;
  energyConsumptionStatement3?: string;
  hotWaterTank?: string;
  hotWaterSystem?: string;
  heatingPipesInsulation?: string;
  buildingDrawingsAndPlans?: string[];
}

export interface StatusQuoResponse {
  address?: string;
  buildYear?: number;
  cellingRenovation?: string;
  doorRenovation?: string;
  floorInsulation?: string;
  heatingSystemAge?: string;
  heatingType?: string;
  houseType?: string;
  interest?: string;
  isCompleted?: boolean;
  outerWallInsulation?: string;
  windowRenovation?: string;
  housingUnits?: number;
  outerDoorType?: string;
  energyCertificate?: string;
}

export interface OfferRequest {
  address?: string;
  city?: string;
  comment?: string;
  contactNumber?: string;
  email?: string;
  firstName?: string;
  lastLame?: string;
  plan?: string;
  postcode?: string;
  submitDate?: number;
}

export interface RenovationReport {
  createdDate?: number;
  completed?: boolean;
  houseToday?: string;
  houseTomorrow?: string;
  savings?: string;
  isfp?: string;
  isfpLight: string;
  energyCertificate: string;
  question: string[];
  answer: string[];
}

export enum UserRoles {
  admin = "admin",
  consultant = "consultant",
  bankManager = "bank-manager",
}

export type UserResponse = {
  id: string;
  name: string;
  email: string;
  shortLink?: string;
  groups?: UserRoles[];
  tenant?: {
    id: string;
    name: string;
    displayName: string;
  };
};

export enum StatusCollor {
  red = "#da3319",
  yellow = "#ffde5a",
  green = "#72ad47",
  none = "transparent",
}

export interface BenefitsResponse {
  subsidy: number;
  minSavings: number;
  maxSavings: number;
}

export interface StatusResponse {
  color: StatusCollor;
  minSavings: number;
  maxSavings: number;
  subsidy: number;
}

export enum WindowRenovation {
  before1979 = "Fenstertausch vor 1979",
  between1979And1995 = "Fenstertausch zwischen 1979 und 1995",
  between1995And2019 = "Fenstertausch zwischen 1995 und 2019",
  from2020 = "Fenstertausch 2020 oder später",
  none = "kein Fenstertausch",
}

export enum HeatingSystemAge {
  moreThan30Years = "vor mehr als 30 Jahren",
  between20To30Years = "vor 20 bis 30 Jahren",
  between10To20Years = "vor 10 bis 20 Jahren",
  lessThan10Years = "vor weniger als 10 Jahren",
  lessThan5Years = "vor weniger als 5 Jahren",
}

export enum OuterWallInsulation {
  before1968 = "ja, vor 1968",
  between1968And2001 = "ja, zwischen 1968 und 2001",
  from2002 = "ja, 2002 oder später",
  none = "nein",
}

export enum FloorInsulation {
  before1968 = "ja, vor 1968",
  between1968And2001 = "ja, zwischen 1968 und 2001",
  from2002 = "ja, 2002 oder später",
  none = "nein, keine Dämmung",
}

export enum DoorRenovation {
  before1968 = "ja, vor 1968",
  between1968And2001 = "ja, zwischen 1968 und 2001",
  from2002 = "ja, 2002 oder später",
  none = "nein, wir haben noch Originaltüren",
}

export enum CeilingRenovation {
  before1968 = "ja, vor 1968",
  between1968And2001 = "ja, zwischen 1968 und 2001",
  from2002 = "ja, 2002 oder später",
  none = "nein, keine Dämmung",
}

export type FeedbackReport = {
  score: number;
  message?: string;
  followUp: boolean;
};
