import { Status } from "./Schnellcheck";

export type User = {
  email: string;
  givenName: string;
  familyName: string;
};

export enum Product {
  onePager = "onePager",
  isfp = "isfp",
  consumptionCertificate = "consumptionCertificate",
}

export type PropertyInput = {
  type: string;
  address: string;
  area: number;
  yearOfConstruction: number;
  isMonument: boolean;
  heatingSystemType: string;
};

export type Property = {
  id: string;
  property: PropertyInput;
  analysis: PropertyAnalysis;
  ownerEmail: string;
  createdAt: number;
};

export type KfwPropertyReform = {
  consumption: EnergyConsumption;
  status: Status;
  costs: KfwRenovationCosts;
};

export type SanierungspflichtPropertyReform = {
  consumption: EnergyConsumption;
  status: Status;
  costs: SanierungspflichtRenovationCosts;
};

export type PropertyAnalysis = {
  kfw85ee: KfwPropertyReform;
  sanierungspflicht: SanierungspflichtPropertyReform;
  consumption: EnergyConsumption;
  status: Status;
};

export type EnergyConsumption = {
  energyClass: string;
  cO2Emission: number;
  trees: number;
  primaryEnergy: number;
  finalEnergy: number;
  energyRequirement: number;
  energyConsumption: number;
  costPerYear: number;
};

export type KfwRenovationCosts = {
  buildingEnvelopeCosts: number;
  airWaterHeatPumpCosts: number;
  heatingOptimizationCosts: number;
  pVSystemCosts: number;
  ventilationSystemCosts: number;
  planningCosts: number;
  totalCost: number;
  subsidy: number;
};

export type SanierungspflichtRenovationCosts = {
  buildingEnvelopeCosts: number;
  airWaterHeatPumpCosts: number;
  heatingOptimizationCosts: number;
  planningCosts: number;
  totalCost: number;
  subsidy: number;
};

export enum Themes {
  NOVO = "novo",
  NOVO_DARK = "novoDark",
  DZ = "dz",
  PSD = "psd",
  VB = "vb",
  BSH = "bsh",
}
