import { useCallback, useState } from "react";
import { apiEndpoint } from "../utils/utils";
import { useAuthAxios } from "./useAuthAxios";
import { Product } from "../types/cockpit/types";

export const API_ENDPOINT = `${apiEndpoint()}/vouchers/count`;

export interface FetchVoucherCountResponse {
  count: number;
}
export default function useFetchVoucherCount() {
  const { authAxios, data, running } = useAuthAxios<FetchVoucherCountResponse>();
  const [reset, setReset] = useState(false);

  const getVoucherCount = useCallback(
    async (product: Product) => {
      setReset(false);
      return await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}?product=${product}&used=false&assigned=false`,
      });
    },
    [authAxios],
  );

  const clearVoucherCount = () => setReset(true);

  return {
    getVoucherCount,
    clearVoucherCount,
    count: data?.count,
    hasVouchers: !reset && data && data.count > 0,
    isLoading: running,
  };
}
