import { Box, BoxProps, styled } from "@mui/material";
import ThemeSelector, { ThemeSelectorProps } from "./ThemeSelector";
import { env } from "../../utils/utils";
import { useState } from "react";

type DevToolsProps = ThemeSelectorProps;
interface DevToolsPanelProps extends BoxProps {
  open?: boolean;
}

const DevToolsPanel = styled(Box)<DevToolsPanelProps>(({ open = false, theme }) => ({
  position: "fixed",
  right: open ? "0px" : "-150px",
  zIndex: 800,
  bottom: "30px",
  width: "150px",
  height: "fit-content",
  padding: "0 10px 10px 10px",
  boxShadow: "-1px 1px 4px 0px #656565",
  borderRadius: "4px",
  border: "solid",
  transition: "right 600ms ease-out",
  backgroundColor: theme.palette.background.paper,
  "&:before": {
    content: open ? '"⏵"' : '"⏴"',
    fontWeight: 700,
    textAlign: "center",
    position: "relative",
    display: "block",
    left: "-35px",
    top: "10px",
    backgroundColor: theme.palette.background.paper,
    width: "25px",
    height: "30px",
    borderRadius: "12px 0 0 12px",
    boxShadow: "-1px 1px 1px 0px #656565",
    border: "solid",
    borderRight: "none",
    cursor: "pointer",
    padding: "2px 0",
  },
}));

export default function DevTools({ themes, currentTheme, setTheme }: DevToolsProps) {
  const [open, setOpen] = useState(false);
  const toggler = (e: React.MouseEvent<HTMLDivElement>) => {
    const { target } = e;
    if (e.clientX > (target as HTMLDivElement).offsetLeft) {
      return;
    }
    setOpen(!open);
  };
  if (env() !== "dev") {
    return <></>;
  }

  return (
    <DevToolsPanel open={open} onClick={toggler}>
      <ThemeSelector themes={themes} currentTheme={currentTheme} setTheme={setTheme} />
    </DevToolsPanel>
  );
}
