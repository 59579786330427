import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NovoLogo from "../../components/common/NovoLogo";
import SideMenu from "./SideMenu";
import CssBaseline from "@mui/material/CssBaseline";
import { Navigate, Outlet, useOutlet } from "react-router-dom";
import Smartlook from "smartlook-client";
import { useEffect, useState } from "react";
import InviteNewLeadDialog from "./leads/InviteNewLeadDialog";
import { Button, Stack, useScrollTrigger } from "@mui/material";
import SupportActions from "./SupportActions";

export default function Cockpit() {
  const outlet = useOutlet();
  const [openNewInvitation, setOpenNewInvitation] = useState<boolean>(false);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const toggleNewInvitation = () => {
    setOpenNewInvitation(!openNewInvitation);
  };

  useEffect(() => {
    Smartlook.init("5e43c176485b8edcd59da14bec2d5d2e2f2c3ef0", { region: "eu" });
  }, []);

  if (!outlet) {
    return <Navigate to='/cockpit/leads' replace />;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#f6f6f6",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar position='sticky' sx={{ backgroundColor: "background.default", top: 0 }}>
        <Toolbar>
          <NovoLogo height={48} sx={{ flexGrow: 1, cursor: "pointer" }} onClick={() => window.location.reload()} />
          <Stack direction='row' alignItems='center' spacing={2}>
            <Button
              data-cy='always-visible-invite-customer-btn'
              variant={trigger ? "contained" : "outlined"}
              color={trigger ? "primary" : "secondary"}
              sx={{ fontSize: 12 }}
              onClick={toggleNewInvitation}
            >
              Kunden Einladen
            </Button>
            <SupportActions />
          </Stack>
        </Toolbar>
      </AppBar>
      <SideMenu />
      <Outlet />
      <InviteNewLeadDialog open={openNewInvitation} onClose={toggleNewInvitation} />
    </Box>
  );
}
