import { useEffect, useState } from "react";
import useFetchLeads from "../../../hooks/useFetchLeads";
import { Lead, Product } from "../../../types/cockpit/types";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import InviteTable from "./InviteTable";
import Box from "@mui/material/Box";

export interface InviteProps {
  id: string;
  name: string;
  email: string;
  product: Product;
  createdAt: Date;
}

export default function InviteList() {
  const { getLeads, leads, isLoading, error } = useFetchLeads();
  const [invites, setInvites] = useState<Array<InviteProps>>();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [filteredInvites, setFilteredInvites] = useState<Array<InviteProps>>();

  useEffect(() => {
    getLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const invites = transformInvites(leads);
    setInvites(invites);
  }, [leads]);

  const handleSearch = (event: React.SyntheticEvent, value: string) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    const searchFilter = (invite: InviteProps) => {
      if (!searchQuery) {
        return true;
      }
      let productLabel = "";
      switch (invite.product) {
        case Product.consumptionCertificate:
          productLabel = "Energieausweis";
          break;
        case Product.isfp:
          productLabel = "Schnellcheck / iSFP";
          break;
        case Product.onePager:
          productLabel = "Sanierungsgutachten";
          break;
      }
      const normalisedQuery = searchQuery.toLowerCase();
      return (
        invite.name?.toLowerCase().includes(normalisedQuery) ||
        invite.email?.toLowerCase().includes(normalisedQuery) ||
        productLabel.toLowerCase().includes(normalisedQuery) ||
        invite.createdAt?.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" }).toLowerCase().includes(normalisedQuery)
      );
    };
    setFilteredInvites(invites?.filter(searchFilter));
  }, [invites, searchQuery]);

  const handleRefresh = () => {
    getLeads();
  };

  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4, mx: 2 }}>
      <Stack spacing={2}>
        <TableTopActions onSearchChange={handleSearch} onRefresh={handleRefresh} />
        <InviteTable isLoading={isLoading} error={error} invites={filteredInvites} />
      </Stack>
    </Box>
  );
}

type TableTopActionsProps = {
  onSearchChange: (event: React.SyntheticEvent, value: string) => void;
  onRefresh: () => void;
};
function TableTopActions({ onSearchChange, onRefresh }: TableTopActionsProps) {
  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <Autocomplete
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label='Suchen'
              placeholder='Name, E-mail, etc...'
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          options={[]}
          onChange={onSearchChange}
          sx={{ flexGrow: 4, bgcolor: "background.default" }}
        />
        <Button data-cy='refresh-lead-list-btn' variant='outlined' color='secondary' sx={{ fontSize: 12 }} onClick={onRefresh}>
          <RefreshIcon />
        </Button>
      </Stack>
    </Box>
  );
}

function transformInvites(leads?: Lead[]): InviteProps[] | undefined {
  if (!leads) {
    return;
  }
  return leads
    .map((lead) => {
      const productRequest = getCCInvite(lead) || getIsfpInvite(lead) || getOPInvite(lead);

      return {
        id: lead.id,
        name: lead.name,
        email: lead.email,
        product: productRequest?.product,
        createdAt: productRequest?.createdAt,
      };
    })
    .filter((invite) => invite.product !== undefined)
    .map((invite) => {
      let createdAt;
      if (!invite.createdAt) {
        createdAt = new Date(0);
      } else {
        createdAt = new Date(invite.createdAt);
      }

      return {
        id: invite.id,
        name: invite.name,
        email: invite.email,
        product: invite.product!,
        createdAt,
      };
    });
}

const getCCInvite = (lead: Lead) => {
  const consumptionCertificate = lead.properties?.find((property) => property.consumptionCertificate !== undefined)?.consumptionCertificate;
  if (consumptionCertificate) return;
  return lead.productInvites?.find((invite) => invite.product === Product.consumptionCertificate);
};

const getIsfpInvite = (lead: Lead) => {
  const isfp = lead.properties?.find((property) => property.isfp !== undefined)?.isfp;
  if (isfp?.bubbleFlow?.statusQuoResponse?.isCompleted) return;
  return lead.productInvites?.find((invite) => invite.product === Product.isfp);
};

const getOPInvite = (lead: Lead) => {
  return lead.productInvites?.find((invite) => invite.product === Product.onePager);
};
