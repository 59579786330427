import { Delete, Visibility } from "@mui/icons-material";
import { Button, Chip, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { DataGrid, GridColDef, deDE } from "@mui/x-data-grid";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import useDeleteProperty from "../../../hooks/useDeleteProperty";
import useFetchProperties from "../../../hooks/useFetchProperties";
import { Property } from "../../../types/types";
import { convertClassToColor, formatEuros, formatNum } from "../../../utils/utils";

type PropertiesListEntry = {
  id: string;
  type: string;
  address: string;
  area: number;
  yearOfConstruction: number;
  isMonument: boolean;
  heatingSystemType: string;
  energyClass: string;
  energyConsumption: number;
  costPerYear: number;
  cO2Emission: number;
};

const convert = (props: Property[] = []): PropertiesListEntry[] => {
  if (typeof props === "object" && !Array.isArray(props)) {
    return [];
  }
  return props.map((p) => ({
    id: p.id,
    type: p.property.type,
    address: p.property.address,
    area: p.property.area,
    yearOfConstruction: p.property.yearOfConstruction,
    isMonument: p.property.isMonument,
    heatingSystemType: p.property.heatingSystemType,
    energyClass: p.analysis.consumption.energyClass,
    energyConsumption: p.analysis.consumption.energyConsumption,
    costPerYear: p.analysis.consumption.costPerYear,
    cO2Emission: p.analysis.consumption.cO2Emission,
  }));
};

export default function Properties() {
  const navigate = useNavigate();
  const deleteProperty = useDeleteProperty();
  const { properties, isLoading } = useFetchProperties();
  const intl = useIntl();

  const rows = convert(properties);

  const columns: GridColDef[] = [
    {
      field: "type",
      flex: 0.6,
      headerName: intl.formatMessage({ id: "app.portfolio.type" }),
      valueFormatter: (params) => `${intl.formatMessage({ id: `app.add-property.${params.value}` })}`,
    },
    { field: "address", flex: 0.8, headerName: intl.formatMessage({ id: "app.portfolio.address" }) },
    {
      field: "area",
      flex: 0.4,
      headerName: intl.formatMessage({ id: "app.portfolio.area" }),
      type: "number",
      valueFormatter: (params) => `${formatNum(params.value)} ${intl.formatMessage({ id: "app.portfolio.sqm" })}`,
    },
    {
      field: "yearOfConstruction",
      flex: 0.4,
      headerName: intl.formatMessage({ id: "app.portfolio.year" }),
      type: "number",
      valueFormatter: (params) => params.value,
    },
    { field: "isMonument", headerName: intl.formatMessage({ id: "app.portfolio.monument" }), type: "boolean" },
    {
      field: "heatingSystemType",
      flex: 0.4,
      headerName: intl.formatMessage({ id: "app.portfolio.heating" }),
      type: "string",
      valueFormatter: (params) => `${intl.formatMessage({ id: `app.add-property.${params.value}` })} ${intl.formatMessage({ id: "app.portfolio.heating" })}`,
    },
    {
      field: "energyClass",
      flex: 0.3,
      headerName: intl.formatMessage({ id: "app.portfolio.class" }),
      renderCell: (params) => {
        return <Chip label={params.value} style={{ backgroundColor: `${convertClassToColor(params.value)}` }} />;
      },
    },
    {
      field: "energyConsumption",
      flex: 0.5,
      headerName: intl.formatMessage({ id: "app.portfolio.consumption" }),
      valueFormatter: (params) => `${formatNum(params.value)} kWh/m2`,
    },
    {
      field: "costPerYear",
      flex: 0.4,
      headerName: intl.formatMessage({ id: "app.portfolio.cost" }),
      valueFormatter: (params) => `${formatEuros(params.value)} €`,
    },
    { field: "cO2Emission", flex: 0.4, headerName: "CO2", valueFormatter: (params) => `${formatNum(params.value)} kg` },
    {
      field: "actions",
      width: 140,
      headerName: "Funktionen",
      renderCell: (params) => {
        return (
          <>
            <IconButton title='Details ansehen' component={Link} to={`${params.row.id}`}>
              <Visibility color='primary' />
            </IconButton>
            <IconButton title='Löschen' onClick={() => deleteProperty(params.row.id)}>
              <Delete color='primary' />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Grid container alignItems='center' justifyContent='center' spacing={0} direction='column' sx={{ minHeight: "100vh" }}>
        <Stack spacing={2} style={{ padding: 20, width: "100%", minHeight: "100vh" }}>
          <Typography variant='h4' align='left'>
            Sanierungsgutachten
          </Typography>
          {
            <DataGrid
              loading={isLoading}
              autoPageSize
              rows={rows}
              columns={columns}
              // TODO: paging
              // initialState={{ pagination: { paginationModel: pageArgs } }}
              // onPaginationModelChange={setPageArgs}
              pageSizeOptions={[5, 10, 25]}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            />
          }
          <Button variant='contained' onClick={() => navigate("property")} sx={{ height: 40, maxWidth: 200 }}>
            <FormattedMessage id='app.portfolio.add' />
          </Button>
        </Stack>
      </Grid>
    </>
  );
}
