import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Smartlook from "smartlook-client";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { useCreateConsumptionCertificate } from "../../../hooks/useCreateConsumptionCertificate";
import { useCreatePurchaseOrder } from "../../../hooks/useCreatePurchaseOrder";
import { useNavigateToStripePayment } from "../../../hooks/useNavigateToStripePayment";
import { OrderPaymentStatus } from "../../../types/PurchaseOrder";
import { Product } from "../../../types/types";
import { bubbleCertificateEndpointRedirect } from "../../../utils/utils";
import BuildingSection from "./BuildingSection";
import ConsumptionCertificateRequest from "./ConsumptionCertificateRequest";
import GeneralSection from "./GeneralSection";
import HeatingSection from "./HeatingSection";
import HotWaterSection from "./HotWaterSection";
import Stepper from "./Stepper";
import useVerifyPLZ from "../../../hooks/useVerifyPLZ";

const today = new Date();
const time = new Date(today.getFullYear() - 1, 0, 0);

export type SectionProps = {
  data: ConsumptionCertificateRequest;
  disabled?: boolean;
  updateHandler: (cert: ConsumptionCertificateRequest, mustVerifyPLZ?: boolean) => void;
};

export default function ConsumptionCertificateRequestPage() {
  const { createConsumptionCertificate, isLoading, createdCertificate } = useCreateConsumptionCertificate();
  const { createPurchaseOrder, createOrderRunning, createdPurchaseOrder } = useCreatePurchaseOrder();
  const { verifyPLZ, plzStatus } = useVerifyPLZ();
  const navigateToStripePayment = useNavigateToStripePayment();
  const [searchParams] = useSearchParams();
  const [consumptionCertificate, setConsumptionCertificate] = useState(new ConsumptionCertificateRequest());
  const formRef = useRef<HTMLFormElement>(null);
  const buildingSectionRef = useRef(null);
  const generalSectionRef = useRef(null);
  const heatingSectionRef = useRef(null);
  const hotWaterSectionRef = useRef(null);
  const [lead] = useState(searchParams.get("lead") ?? undefined); // Lead ID coming from Bubble
  const [leadId] = useState(searchParams.get("leadId") ?? undefined);
  const [voucherId] = useState(searchParams.get("voucherId") ?? undefined);

  const update = (cert: ConsumptionCertificateRequest, mustVerifyPLZ: boolean) => {
    if (!cert.isValid()) {
      cert.validate();
    }
    if (mustVerifyPLZ) {
      verifyPLZ(cert.buildingData.postalCode, time.getTime());
    }
    const certificate = ConsumptionCertificateRequest.deepClone(cert);
    setConsumptionCertificate(certificate);
    certificate.save();
  };

  const submitRequest = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const form = event.currentTarget?.form;
    const isValid = consumptionCertificate.validate();
    setConsumptionCertificate(ConsumptionCertificateRequest.deepClone(consumptionCertificate));
    if (isValid && form && form.checkValidity()) {
      const certReqObject = consumptionCertificate.toConsumptionCertificateState();
      createConsumptionCertificate(certReqObject, { lead, leadId, voucherId });
    } else if (form && form.reportValidity()) {
      const errorElement = findElementOnError(form);
      errorElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  const findElementOnError = (form: HTMLFormElement): Element | undefined => {
    for (let i = 0; i < form.elements.length; i++) {
      if (form.elements[i].ariaInvalid === "true") {
        return form.elements[i];
      }
    }
    return;
  };

  useEffect(() => {
    consumptionCertificate.buildingData.postalCodeUnknown = !!plzStatus && plzStatus !== 200;
    setConsumptionCertificate(ConsumptionCertificateRequest.deepClone(consumptionCertificate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plzStatus]);

  // after certificate was created, automatically create a purchase order for it
  useEffect(() => {
    if (createdCertificate && !createdPurchaseOrder) {
      consumptionCertificate.cleanUp();
      createPurchaseOrder({
        bubbleLeadId: lead,
        leadId: leadId,
        voucherId: voucherId,
        items: [{ id: createdCertificate.id, product: Product.consumptionCertificate }],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCertificate]);

  useEffect(() => {
    if (createdPurchaseOrder) {
      // if resulting order is not free, navigate to stripe to pay it
      if (createdPurchaseOrder.paymentData?.paymentStatus !== OrderPaymentStatus.NoPaymentRequired) {
        navigateToStripePayment(createdPurchaseOrder.id, { redirectTo: bubbleCertificateEndpointRedirect() });
      } else {
        // otherwise just proceed to bubble since certificate is free
        window.location.href = bubbleCertificateEndpointRedirect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdPurchaseOrder]);

  useEffect(() => {
    Smartlook.init("ccdf96019b9dcddb440eb89056ab6f3572810b4e", { region: "eu" });
    const certificate = ConsumptionCertificateRequest.load();
    if (certificate) {
      setConsumptionCertificate(certificate);
    }
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Grid
        container
        spacing={2}
        ref={formRef}
        component={"form"}
        sx={{
          mt: 5,
          maxWidth: "lg",
          mx: "auto",
        }}
      >
        <Grid xs={12} mb={10}>
          <Typography variant='h2' textAlign='center' fontWeight={600}>
            NOVO
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <Typography variant='h3' gutterBottom>
            Ihr Verbrauchausweis
          </Typography>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} sm={6} />
        <Grid xs={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>
        <Grid xs={12} sx={{ display: { xs: "block", md: "none" } }}>
          <Typography variant='h4'>{consumptionCertificate.sections[0]}</Typography>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} sm={5}>
          <Stepper steps={consumptionCertificate.sections} refs={{ buildingSectionRef, generalSectionRef, heatingSectionRef, hotWaterSectionRef }} />
        </Grid>
        <Grid ref={buildingSectionRef} xs={12} md={7}>
          <BuildingSection data={consumptionCertificate} updateHandler={update} />
        </Grid>
        <Grid xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid xs={12} sx={{ display: { xs: "block", md: "none" } }}>
          <Typography variant='h4'>{consumptionCertificate.sections[1]}</Typography>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} sm={5} />
        <Grid ref={generalSectionRef} xs={12} md={7}>
          <GeneralSection data={consumptionCertificate} updateHandler={update} disabled={consumptionCertificate.divertToRequirementCertificate() === 1} />
        </Grid>
        <Grid xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid xs={12} sx={{ display: { xs: "block", md: "none" } }}>
          <Typography variant='h4'>{consumptionCertificate.sections[2]}</Typography>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} sm={5} />
        <Grid ref={heatingSectionRef} xs={12} md={7}>
          <HeatingSection data={consumptionCertificate} updateHandler={update} disabled={consumptionCertificate.divertToRequirementCertificate() !== 0} />
        </Grid>
        <Grid xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid xs={12} sx={{ display: { xs: "block", md: "none" } }}>
          <Typography variant='h4'>{consumptionCertificate.sections[3]}</Typography>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} sm={5} />
        <Grid ref={hotWaterSectionRef} xs={12} md={7}>
          <HotWaterSection data={consumptionCertificate} updateHandler={update} disabled={consumptionCertificate.divertToRequirementCertificate() !== 0} />
        </Grid>
        <Grid xs={5} />
        <Grid xs={12} md={7} mt={10}>
          <FormControlLabel
            data-cy='data-correctness-confirmation-checkbox'
            control={<Checkbox required={true} />}
            disabled={consumptionCertificate.divertToRequirementCertificate() !== 0}
            label='Ich habe meine Eingaben und die Energiewerte überprüft und keine Fehler gefunden.'
          />
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} sm={5} />
        <Grid xs={12} md={7} mt={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <ButtonWithSpinner
            data-cy='submit-consumption-cert-button'
            loading={isLoading || createOrderRunning}
            variant={"contained"}
            label='Verbrauchsausweis bestellen'
            disabled={consumptionCertificate.divertToRequirementCertificate() !== 0}
            onClick={(e) => submitRequest(e)}
          />
        </Grid>
        <Grid xs={12} mb={10} />
      </Grid>
    </Box>
  );
}
