import { subMonths } from "date-fns";
import { ConsumptionCertificateState } from "../../../types/ConsumptionCertificateState";
import {
  Purpose,
  getPurpose,
  BuildingType,
  getBuildingType,
  Partition,
  getPartition,
  GermanState,
  getGermanState,
  Month,
  getMonth,
  Reforms,
} from "./ConsumptionCertificateRequestEnums";

export default class ConsumptionCertificateRequest {
  sections = ["Gebäude", "Allgemein", "Heizung", "Warmwasserdaten"];
  buildingData: BuildingData;
  generalData: GeneralData;
  heatingData: HeatingData;
  hotWaterData: HotWaterData;

  constructor() {
    this.buildingData = new BuildingData();
    this.generalData = new GeneralData();
    this.heatingData = new HeatingData();
    this.hotWaterData = new HotWaterData(this.heatingData.heatingSystemOptions);
  }

  isValid(): boolean {
    return this.buildingData.isValid() && this.generalData.isValid() && this.heatingData.isValid() && this.hotWaterData.isValid();
  }

  validate(): boolean {
    return this.buildingData.validate() && this.generalData.validate() && this.heatingData.validate() && this.hotWaterData.validate(this.heatingData);
  }

  divertToRequirementCertificate(): number {
    if (this.buildingData.divertToRequirementCertificate()) return 1;
    else if (this.generalData.divertToRequirementCertificate()) return 2;
    return 0;
  }

  toConsumptionCertificateState(): ConsumptionCertificateState {
    return {
      reason: getPurpose(this.buildingData.purpose),
      typeOfBuilding: getBuildingType(this.buildingData.type),
      area: Number.parseInt(this.buildingData.surface),
      yearOfConstruction: Number.parseInt(this.buildingData.constructionYear),
      reform: this.buildingData.reform,
      numberOfApartments: Number.parseInt(this.buildingData.houseCount),
      buildingPart: getPartition(this.buildingData.partition),
      address: this.buildingData.address,
      postalCode: this.buildingData.postalCode,
      location: this.buildingData.town,
      bundesland: getGermanState(this.buildingData.state),
      photo: this.buildingData.photo,

      heatingSystemType: this.heatingData.heatingSystem,
      yearOfHeatingInstalation: Number.parseInt(this.heatingData.installationYear),
      endMonthOfBill: getMonth(this.heatingData.readingMonth),
      yearOfBill: Number.parseInt(this.heatingData.readingYear),
      verbrauch1: Number.parseInt(this.heatingData.readingValue),
      verbrauch2: Number.parseInt(this.heatingData.readingValueYearBefore),
      verbrauch3: Number.parseInt(this.heatingData.readingValueTwoYearsBefore),
      secondaryHeatingSystemType: this.heatingData.secondaryHeatingSystemType,
      secondaryYearOfHeatingInstalation: Number.parseInt(this.heatingData.secondaryYearOfHeatingInstalation),
      secondaryVerbrauch1: Number.parseInt(this.heatingData.secondaryReadingValue),
      secondaryVerbrauch2: Number.parseInt(this.heatingData.secondaryReadingValueYearBefore),
      secondaryVerbrauch3: Number.parseInt(this.heatingData.secondaryReadingValueTwoYearsBefore),

      ventilationType: this.generalData.ventilationTypes,
      cooling: this.generalData.coolingTypes,
      renewable: this.generalData.renewableEnergySources,
      renovationMeasures: this.generalData.renovationMeasures,

      warmWaterStatus: this.hotWaterData.consumptionType,
      warmWatterPercentage: Number.parseInt(this.hotWaterData.hotWaterPercentage),
      warmWaterSystemType: this.hotWaterData.hotWaterSystem,
      warmWaterVerbrauch1: Number.parseInt(this.hotWaterData.readingValue),
      warmWaterVerbrauch2: Number.parseInt(this.hotWaterData.readingValueYearBefore),
      warmWaterVerbrauch3: Number.parseInt(this.hotWaterData.readingValueTwoYearsBefore),
      secondaryWarmWaterStatus: this.hotWaterData.secondaryConsumptionType,
      secondaryWarmWatterPercentage: Number.parseInt(this.hotWaterData.secondaryHotWaterPercentage),
    };
  }

  static deepClone(original: ConsumptionCertificateRequest) {
    const certificate = new ConsumptionCertificateRequest();
    certificate.buildingData.purpose = original.buildingData.purpose;
    certificate.buildingData.purposeError = original.buildingData.purposeError;
    certificate.buildingData.type = original.buildingData.type;
    certificate.buildingData.typeError = original.buildingData.typeError;
    certificate.buildingData.reform = original.buildingData.reform;
    certificate.buildingData.reformError = original.buildingData.reformError;
    certificate.buildingData.partition = original.buildingData.partition;
    certificate.buildingData.partitionError = original.buildingData.partitionError;
    certificate.buildingData.houseCount = original.buildingData.houseCount;
    certificate.buildingData.houseCountError = original.buildingData.houseCountError;
    certificate.buildingData.constructionYear = original.buildingData.constructionYear;
    certificate.buildingData.constructionYearError = original.buildingData.constructionYearError;
    certificate.buildingData.surface = original.buildingData.surface;
    certificate.buildingData.surfaceError = original.buildingData.surfaceError;
    certificate.buildingData.address = original.buildingData.address;
    certificate.buildingData.addressError = original.buildingData.addressError;
    certificate.buildingData.postalCode = original.buildingData.postalCode;
    certificate.buildingData.postalCodeError = original.buildingData.postalCodeError;
    certificate.buildingData.postalCodeUnknown = original.buildingData.postalCodeUnknown;
    certificate.buildingData.town = original.buildingData.town;
    certificate.buildingData.townError = original.buildingData.townError;
    certificate.buildingData.state = original.buildingData.state;
    certificate.buildingData.stateError = original.buildingData.stateError;
    certificate.buildingData.photo = original.buildingData.photo;

    certificate.generalData.ventilationTypes = [...original.generalData.ventilationTypes];
    certificate.generalData.coolingTypes = [...original.generalData.coolingTypes];
    certificate.generalData.renewableEnergySources = [...original.generalData.renewableEnergySources];
    certificate.generalData.isEmptyProperty = original.generalData.isEmptyProperty;
    certificate.generalData.renovationMeasures = original.generalData.renovationMeasures;

    certificate.heatingData.installationYear = original.heatingData.installationYear;
    certificate.heatingData.installationYearError = original.heatingData.installationYearError;
    certificate.heatingData.heatingSystem = original.heatingData.heatingSystem;
    certificate.heatingData.heatingSystemError = original.heatingData.heatingSystemError;
    certificate.heatingData.readingMonth = original.heatingData.readingMonth;
    certificate.heatingData.readingMonthError = original.heatingData.readingMonthError;
    certificate.heatingData.readingYear = original.heatingData.readingYear;
    certificate.heatingData.readingYearError = original.heatingData.readingYearError;
    certificate.heatingData.readingValue = original.heatingData.readingValue;
    certificate.heatingData.readingValueError = original.heatingData.readingValueError;
    certificate.heatingData.readingValueYearBefore = original.heatingData.readingValueYearBefore;
    certificate.heatingData.readingValueYearBeforeError = original.heatingData.readingValueYearBeforeError;
    certificate.heatingData.readingValueTwoYearsBefore = original.heatingData.readingValueTwoYearsBefore;
    certificate.heatingData.readingValueTwoYearsBeforeError = original.heatingData.readingValueTwoYearsBeforeError;
    certificate.heatingData.secondaryHeatingSystemType = original.heatingData.secondaryHeatingSystemType;
    certificate.heatingData.secondaryYearOfHeatingInstalation = original.heatingData.secondaryYearOfHeatingInstalation;
    certificate.heatingData.secondaryInstallationYearError = original.heatingData.secondaryInstallationYearError;
    certificate.heatingData.secondaryReadingValue = original.heatingData.secondaryReadingValue;
    certificate.heatingData.secondaryReadingValueError = original.heatingData.secondaryReadingValueError;
    certificate.heatingData.secondaryReadingValueYearBefore = original.heatingData.secondaryReadingValueYearBefore;
    certificate.heatingData.secondaryReadingValueYearBeforeError = original.heatingData.secondaryReadingValueYearBeforeError;
    certificate.heatingData.secondaryReadingValueTwoYearsBefore = original.heatingData.secondaryReadingValueTwoYearsBefore;
    certificate.heatingData.secondaryReadingValueTwoYearsBeforeError = original.heatingData.secondaryReadingValueTwoYearsBeforeError;
    certificate.heatingData.months = original.heatingData.months;

    certificate.hotWaterData.consumptionType = original.hotWaterData.consumptionType;
    certificate.hotWaterData.consumptionTypeError = original.hotWaterData.consumptionTypeError;
    certificate.hotWaterData.hotWaterPercentage = original.hotWaterData.hotWaterPercentage;
    certificate.hotWaterData.hotWaterPercentageError = original.hotWaterData.hotWaterPercentageError;
    certificate.hotWaterData.hotWaterSystem = original.hotWaterData.hotWaterSystem;
    certificate.hotWaterData.hotWaterSystemError = original.hotWaterData.hotWaterSystemError;
    certificate.hotWaterData.readingValue = original.hotWaterData.readingValue;
    certificate.hotWaterData.readingValueError = original.hotWaterData.readingValueError;
    certificate.hotWaterData.readingValueYearBefore = original.hotWaterData.readingValueYearBefore;
    certificate.hotWaterData.readingValueYearBeforeError = original.hotWaterData.readingValueYearBeforeError;
    certificate.hotWaterData.readingValueTwoYearsBefore = original.hotWaterData.readingValueTwoYearsBefore;
    certificate.hotWaterData.readingValueTwoYearsBeforeError = original.hotWaterData.readingValueTwoYearsBeforeError;
    certificate.hotWaterData.secondaryConsumptionType = original.hotWaterData.secondaryConsumptionType;
    certificate.hotWaterData.secondaryConsumptionTypeError = original.hotWaterData.secondaryConsumptionTypeError;
    certificate.hotWaterData.secondaryHotWaterPercentage = original.hotWaterData.secondaryHotWaterPercentage;
    certificate.hotWaterData.secondaryHotWaterPercentageError = original.hotWaterData.secondaryHotWaterPercentageError;

    return certificate;
  }

  save(): void {
    localStorage.setItem("consumption-certificate-request", JSON.stringify(this));
  }

  static load(): ConsumptionCertificateRequest | undefined {
    const item = localStorage.getItem("consumption-certificate-request");
    if (!item) {
      return;
    }
    const data = JSON.parse(item);
    const certificate = new ConsumptionCertificateRequest();
    Object.assign(certificate.buildingData, data.buildingData);
    // JSON parser will return empty object, which is not a falsy value
    certificate.buildingData.photo = undefined;
    Object.assign(certificate.generalData, data.generalData);
    Object.assign(certificate.heatingData, data.heatingData);
    Object.assign(certificate.hotWaterData, data.hotWaterData);
    return certificate;
  }

  cleanUp(): void {
    localStorage.removeItem("consumption-certificate-request");
  }
}

class BuildingData {
  purpose = "";
  purposeError: boolean;
  purposeOptions = Object.keys(Purpose);
  type = "";
  typeError: boolean;
  typeOptions = Object.keys(BuildingType);
  reform = "";
  reformError: boolean;
  reformOptions = Object.keys(Reforms);
  partition = "";
  partitionError: boolean;
  partitionOptions = Object.keys(Partition);
  houseCount = "";
  houseCountError: boolean;
  constructionYear = "";
  constructionYearError: boolean;
  maxConstructionYear = new Date().getFullYear() - 3;
  surface = "";
  surfaceError: boolean;
  address = "";
  addressError: boolean;
  postalCode = "";
  postalCodeError: boolean;
  postalCodeUnknown: boolean;
  town = "";
  townError: boolean;
  state = "";
  stateOptions = Object.keys(GermanState);
  stateError: boolean;
  photo?: File;

  isValid(): boolean {
    return !(
      this.purposeError ||
      this.typeError ||
      this.partitionError ||
      this.houseCountError ||
      this.constructionYearError ||
      this.surfaceError ||
      this.addressError ||
      this.postalCodeError ||
      this.postalCodeUnknown ||
      this.townError ||
      this.stateError
    );
  }

  validate(): boolean {
    this.purposeError = !this.purpose;
    this.typeError = !this.type;
    this.partitionError = !this.partition;
    this.houseCountError = !this.houseCount || Number.parseInt(this.houseCount) <= 0;
    this.constructionYearError =
      !this.constructionYear || Number.parseInt(this.constructionYear) > this.maxConstructionYear || Number.parseInt(this.constructionYear) < 1700;
    this.surfaceError = !this.surface || Number.parseInt(this.surface) <= 0;
    this.addressError = !this.address;
    this.postalCodeError = !this.postalCode || !this.postalCode.match(/^[0-9]{5}$/);
    this.townError = !this.town;
    this.stateError = !this.state;

    return this.isValid();
  }

  divertToRequirementCertificate(): boolean {
    return (
      (this.constructionYear !== "" &&
        Number.parseInt(this.constructionYear) < 1977 &&
        this.reform !== Reforms["Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten"]) ||
      this.partition === Partition["Teil des Nichtwohngebäudes"] ||
      Number.parseInt(this.houseCount) > 4
    );
  }
}

class GeneralData {
  ventilationTypes = new Array<string>();
  ventilationOptions = new Array<string>();
  coolingTypes = new Array<string>();
  coolingOptions = new Array<string>();
  renewableEnergySources = new Array<string>();
  renewableEnergyOptions = new Array<string>();
  isEmptyProperty = false;
  renovationMeasures = "";

  constructor() {
    this.ventilationOptions.push("Fensterlüftung");
    this.ventilationOptions.push("Schachtlüftung");
    this.ventilationOptions.push("Lüftungsanlage mit Wärmerückgewinnung");
    this.ventilationOptions.push("Lüftungsanlage ohne Wärmerückgewinnung");

    this.coolingOptions.push("Kühlung aus Strom");
    this.coolingOptions.push("Gelieferte Kälte");
    this.coolingOptions.push("Passive Kühlung");
    this.coolingOptions.push("Kühlung aus Wärme");

    this.renewableEnergyOptions.push("Solarthermie");
    this.renewableEnergyOptions.push("Photovoltaik");
    this.renewableEnergyOptions.push("Wärmepumpe");
    this.renewableEnergyOptions.push("Biomasse");
  }

  isValid(): boolean {
    return true;
  }

  validate(): boolean {
    return this.isValid();
  }

  divertToRequirementCertificate(): boolean {
    return this.isEmptyProperty;
  }
}

class HeatingData {
  installationYear = "";
  installationYearError: boolean;
  heatingSystem = "";
  heatingSystemError: boolean;
  heatingSystemOptions = new Array<string>();
  readingMonth = "";
  readingMonthError: boolean;
  months = Object.keys(Month);
  readingYear = "";
  readingYearError: boolean;
  years = new Array<string>();
  readingValue = "";
  readingValueError: boolean;
  readingValueYearBefore = "";
  readingValueYearBeforeError: boolean;
  readingValueTwoYearsBefore = "";
  readingValueTwoYearsBeforeError: boolean;
  secondaryHeatingSystemType = "";
  secondaryYearOfHeatingInstalation = "";
  secondaryInstallationYearError: boolean;
  secondaryReadingValue = "";
  secondaryReadingValueError: boolean;
  secondaryReadingValueYearBefore = "";
  secondaryReadingValueYearBeforeError: boolean;
  secondaryReadingValueTwoYearsBefore = "";
  secondaryReadingValueTwoYearsBeforeError: boolean;

  constructor() {
    this.heatingSystemOptions.push("Heizöl in kWh Heizwert");
    this.heatingSystemOptions.push("Heizöl in kWh Brennwert");
    this.heatingSystemOptions.push("Erdgas in kWh Heizwert");
    this.heatingSystemOptions.push("Erdgas in kWh Brennwert");
    this.heatingSystemOptions.push("Flüssiggas in kWh Heizwert");
    this.heatingSystemOptions.push("Steinkohle in kWh Heizwert");
    this.heatingSystemOptions.push("Braunkohle in kWh Heizwert");
    this.heatingSystemOptions.push("Biogas in kWh Heizwert");
    this.heatingSystemOptions.push("Biogas in kWh Brennwert");
    this.heatingSystemOptions.push("Biogas, gebäudenah erzeugt in kWh Heizwert");
    this.heatingSystemOptions.push("Biogas, gebäudenah erzeugt in kWh Brennwert");
    this.heatingSystemOptions.push("biogenes Flüssiggas in kWh Heizwert");
    this.heatingSystemOptions.push("Bioöl in kWh Heizwert");
    this.heatingSystemOptions.push("Bioöl in kWh Brennwert");
    this.heatingSystemOptions.push("Bioöl, gebäudenah erzeugt in kWh Heizwert");
    this.heatingSystemOptions.push("Bioöl, gebäudenah erzeugt in kWh Brennwert");
    this.heatingSystemOptions.push("Holz in kWh Heizwert");
    this.heatingSystemOptions.push("Holz in kWh Brennwert");
    this.heatingSystemOptions.push("Strom netzbezogen in kWh");
    this.heatingSystemOptions.push("Strom gebäudenah erzeugt (aus Photovoltaik, Windkraft) in kWh");
    this.heatingSystemOptions.push("Verdrängungsstrommix für KWK in kWh");
    this.heatingSystemOptions.push("Wärme (Erdwärme, Geothermie, Solarthermie, Umgebungswärme) in kWh");
    this.heatingSystemOptions.push("Kälte (Erdkälte, Umgebungskälte) in kWh");
    this.heatingSystemOptions.push("Abwärme aus Prozessen (prod) in kWh");
    this.heatingSystemOptions.push("Abwärme aus Prozessen (out) in kWh");
    this.heatingSystemOptions.push("Wärme aus KWK, gebäudeintegriert oder gebäudenah in kWh");
    this.heatingSystemOptions.push("Wärme aus Verbrennung von Siedlungsabfällen in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus KWK, fossiler Brennstoff (Stein_/Braunkohle) bzw. Energieträger in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus KWK, fossiler Brennstoff (Gasförmige und flüssige Brennstoffe) bzw. Energieträger in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus KWK, erneuerbarer Brennstoff bzw. Energieträger in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus Heizwerken, fossiler Brennstoff (Stein_/Braunkohle) bzw. Energieträger in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus Heizwerken, fossiler Brennstoff (Gasförmige und flüssige Brennstoffe) bzw. Energieträger in kWh");
    this.heatingSystemOptions.push("Nah_/Fernwärme aus Heizwerken, erneuerbarer Brennstoff bzw. Energieträger in kWh");

    const presentDate = new Date();
    const presentYear = presentDate.getFullYear();

    // the newest bill end date should be maximally 18 months in the past
    const oldestBillYear = subMonths(presentDate, 18).getFullYear();

    for (let year = presentYear; year >= oldestBillYear; year--) {
      this.years.push(`${year}`);
    }
  }

  isValid(): boolean {
    return !(
      this.installationYearError ||
      this.heatingSystemError ||
      this.readingMonthError ||
      this.readingYearError ||
      this.readingValueError ||
      this.readingValueYearBeforeError ||
      this.readingValueTwoYearsBeforeError ||
      this.secondaryInstallationYearError ||
      this.secondaryReadingValueError ||
      this.secondaryReadingValueYearBeforeError ||
      this.secondaryReadingValueTwoYearsBeforeError
    );
  }

  validate(): boolean {
    this.installationYearError = !this.installationYear || Number.parseInt(this.installationYear) <= 0;
    this.heatingSystemError = !this.heatingSystem;
    this.readingMonthError = !this.readingMonth;
    this.readingYearError = !this.readingYear;
    this.readingValueError = !this.readingValue || Number.parseInt(this.readingValue) <= 0;
    this.readingValueYearBeforeError = !this.readingValueYearBefore || Number.parseInt(this.readingValueYearBefore) <= 0;
    this.readingValueTwoYearsBeforeError = !this.readingValueTwoYearsBefore || Number.parseInt(this.readingValueTwoYearsBefore) <= 0;

    this.secondaryInstallationYearError =
      Boolean(this.secondaryHeatingSystemType) && (!this.secondaryYearOfHeatingInstalation || Number.parseInt(this.secondaryYearOfHeatingInstalation) <= 0);
    this.secondaryReadingValueError =
      Boolean(this.secondaryHeatingSystemType) && (!this.secondaryReadingValue || Number.parseInt(this.secondaryReadingValue) <= 0);
    this.secondaryReadingValueYearBeforeError =
      Boolean(this.secondaryHeatingSystemType) && (!this.secondaryReadingValueYearBefore || Number.parseInt(this.secondaryReadingValueYearBefore) <= 0);
    this.secondaryReadingValueTwoYearsBeforeError =
      Boolean(this.secondaryHeatingSystemType) && (!this.secondaryReadingValueTwoYearsBefore || Number.parseInt(this.secondaryReadingValueTwoYearsBefore) <= 0);

    return this.isValid();
  }
}

class HotWaterData {
  consumptionType = "";
  consumptionTypeError: boolean;
  consumptionOptions = new Array<string>();
  hotWaterPercentage = "";
  hotWaterPercentageError: boolean;
  hotWaterSystem = "";
  hotWaterSystemError: boolean;
  hotWaterSystemOptions = new Array<string>();
  readingValue = "";
  readingValueError: boolean;
  readingValueYearBefore = "";
  readingValueYearBeforeError: boolean;
  readingValueTwoYearsBefore = "";
  readingValueTwoYearsBeforeError: boolean;
  secondaryConsumptionType = "";
  secondaryConsumptionTypeError: boolean;
  secondaryConsumptionOptions = new Array<string>();
  secondaryHotWaterPercentage = "";
  secondaryHotWaterPercentageError: boolean;

  constructor(hotWaterSystemOptions: Array<string>) {
    this.consumptionOptions.push("Warmwasser im Verbrauch enthalten");
    this.consumptionOptions.push("Verbrauch ist nicht bekannt");
    this.consumptionOptions.push("Verbrauch separat angeben");
    this.hotWaterSystemOptions = hotWaterSystemOptions;
    this.secondaryConsumptionOptions.push("Warmwasser im Verbrauch enthalten");
    this.secondaryConsumptionOptions.push("Verbrauch ist nicht bekannt");
  }

  isValid(): boolean {
    return !(
      this.consumptionTypeError ||
      this.hotWaterPercentageError ||
      this.hotWaterSystemError ||
      this.readingValueError ||
      this.readingValueYearBeforeError ||
      this.readingValueTwoYearsBeforeError ||
      this.secondaryConsumptionTypeError ||
      this.secondaryHotWaterPercentageError
    );
  }

  validate(heatingData: HeatingData): boolean {
    this.consumptionTypeError = !this.consumptionType;
    this.hotWaterPercentageError =
      this.consumptionType === this.consumptionOptions[0] &&
      (!this.hotWaterPercentage || Number.parseInt(this.hotWaterPercentage) <= 0 || Number.parseInt(this.hotWaterPercentage) > 100);
    this.hotWaterSystemError = this.consumptionType === this.consumptionOptions[2] && !this.hotWaterSystem;
    this.readingValueError = this.consumptionType === this.consumptionOptions[2] && (!this.readingValue || Number.parseInt(this.readingValue) <= 0);
    this.readingValueYearBeforeError =
      this.consumptionType === this.consumptionOptions[2] && (!this.readingValueYearBefore || Number.parseInt(this.readingValueYearBefore) <= 0);
    this.readingValueTwoYearsBeforeError =
      this.consumptionType === this.consumptionOptions[2] && (!this.readingValueTwoYearsBefore || Number.parseInt(this.readingValueTwoYearsBefore) <= 0);
    this.secondaryConsumptionTypeError = Boolean(heatingData.secondaryHeatingSystemType) && !this.secondaryConsumptionType;
    this.secondaryHotWaterPercentageError =
      Boolean(heatingData.secondaryHeatingSystemType) &&
      this.secondaryConsumptionType === this.secondaryConsumptionOptions[0] &&
      (!this.secondaryHotWaterPercentage || Number.parseInt(this.secondaryHotWaterPercentage) <= 0 || Number.parseInt(this.secondaryHotWaterPercentage) > 100);

    return this.isValid();
  }
}
