import Chip from "@mui/material/Chip";
import { EnergyConsumption } from "../types/types";
import { UserResponse, UserRoles } from "../types/cockpit/types";

export const createTags = (input: string[]) => {
  return input.map((e) => <Chip label={e} key={e} />);
};

export const apiEndpoint = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev.novo.eco":
      return process.env.REACT_APP_CUSTOM_SERVER_URL ? process.env.REACT_APP_CUSTOM_SERVER_URL : "https://api-dev.novo.eco";
    case "app-stg.novo.eco":
      return "https://api-stg.novo.eco";
    case "app.novo.eco":
      return "https://api.novo.eco";
    default:
      throw new Error("hostname not recognised");
  }
};

export const stripeKey = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev.novo.eco":
    case "app-stg.novo.eco":
      return "pk_test_51M6wvZLRBLaJ8zNCnBD26IiZZQvLSiIzTej3t5ITo84sOTavek6AZDoY7082Bng93Ag5LhR3zDfWbr13DWlIAWsW00jlOfuoPb";
    case "app.novo.eco":
      return "pk_live_51M6wvZLRBLaJ8zNCIXc4x19Cwrc7ILrN5DfGz85klwkmDntj4tqXoUSyFvUyowmnyVYcIISUZj7NKNN2LAcEKdse00g86HsoMF";
    default:
      throw new Error("hostname not recognised");
  }
};

export type Env = "dev" | "stg" | "prod";

export const env = (): Env | undefined => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev.novo.eco":
      return "dev";
    case "app-stg.novo.eco":
      return "stg";
    case "app.novo.eco":
      return "prod";
    default:
      return undefined;
  }
};

export const bubbleCertificateEndpointRedirect = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev.novo.eco":
    case "app-stg.novo.eco":
      return "https://novo.eco/version-test/energieausweis";
    case "app.novo.eco":
      return "https://novo.eco/energieausweis";
    default:
      throw new Error("bubble hostname not recognised");
  }
};

export const calculateEnergyCostSavings = (consumptionBefore: EnergyConsumption, consumptionAfter: EnergyConsumption): number => {
  const costPerYearBefore = consumptionBefore.costPerYear;
  const costPerYearAfter = consumptionAfter.costPerYear;
  return ((costPerYearBefore - costPerYearAfter) / costPerYearBefore) * 100;
};

export const formatNum = (num?: number): string => {
  return num ? new Intl.NumberFormat("de-de").format(num) : "-";
};

export const formatEuros = (num?: number): string => {
  return num ? new Intl.NumberFormat("de-de", { minimumFractionDigits: 2 }).format(num) : "-";
};

export const convertClassToColor = (energyClass: string) => {
  switch (energyClass) {
    case "A+":
      return "#22fe16";
    case "A":
      return "#97f525";
    case "B":
      return "#bbff00";
    case "C":
      return "#e0fe00";
    case "D":
      return "#fefe02";
    case "E":
      return "#fef200";
    case "F":
      return "#fcda00";
    case "G":
      return "#fe7b00";
    case "H":
      return "#fc3700";
    default:
      return "#ffffff";
  }
};

export const isAdmin = (user?: UserResponse) => {
  return user?.groups?.includes(UserRoles.admin);
};

export const isConsultant = (user?: UserResponse) => {
  return user?.groups?.includes(UserRoles.consultant);
};

export const isBankManager = (user?: UserResponse) => {
  return user?.groups?.includes(UserRoles.bankManager);
};

type AuthzPolicy = {
  [path: string]: UserRoles[];
};

export const isCockpitPathAuthorized = (path: string, user?: UserResponse) => {
  const relevantPath = path.replace("/cockpit/", "").split("/")[0];
  const policyPath = `/${relevantPath}`;
  const policy: AuthzPolicy = {
    "/leads": [UserRoles.consultant, UserRoles.bankManager, UserRoles.admin],
    "/invites": [UserRoles.consultant, UserRoles.bankManager, UserRoles.admin],
    "/consultants": [UserRoles.bankManager, UserRoles.admin],
    "/metrics": [UserRoles.admin],
    "/consumption-certificates": [UserRoles.admin],
    "/one-pager": [UserRoles.admin],
  };
  return user?.groups?.map((role) => policy[policyPath]?.includes(role)).reduce((acc, cur) => acc || cur);
};
