import { useCallback } from "react";
import { GetTenantsMetricsResponse } from "../types/ConsultantMetrics";
import { apiEndpoint } from "../utils/utils";
import { useAuthAxios } from "./useAuthAxios";

const API_ENDPOINT = `${apiEndpoint()}/metrics/tenants`;

export type MetricRangeParams = { [rangeName: string]: { from: number; to: number } };

export default function useFetchTenantsMetrics() {
  const { authAxios, error, running, data } = useAuthAxios<GetTenantsMetricsResponse>();

  const fetchTenantsMetrics = useCallback(
    async (metricRanges?: MetricRangeParams) => {
      return await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}`,
        params: {
          ...(metricRanges ? { ranges: encodeURIComponent(JSON.stringify(metricRanges)) } : {}),
        },
      });
    },
    [authAxios],
  );

  return { fetchTenantsMetrics, error, isLoading: running, metrics: data };
}
