import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useTheme } from "@mui/material/styles";
import { chartsGridClasses } from "@mui/x-charts/ChartsGrid";
import { LineChart } from "@mui/x-charts/LineChart";
import { useEffect, useState } from "react";
import useFetchConsultantMetrics, { MetricRangeParams } from "../../../hooks/useFetchConsultantMetrics";
import { EventType, getRevenueEstimate } from "../../../types/ConsultantMetrics";
import { formatNum } from "../../../utils/utils";

export default function RevenueEstimate() {
  const theme = useTheme();
  const [labels, setLabels] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);
  const [currentYearEstimatedRevenue, setCurrentYearEstimatedRevenue] = useState(0);
  const [percentageChange, setPercentageChange] = useState<number | undefined>();
  const [open, setOpen] = useState<boolean>();
  const [currYearMonths, setCurrYearMonths] = useState<string[]>([]);
  const { getConsultantMetrics, metrics, isLoading } = useFetchConsultantMetrics();

  useEffect(() => {
    const currYearRanges = generateMonthRangesUntilNow();
    const lastYearRange = generatePreviousYearRange();

    setCurrYearMonths(Object.keys(currYearRanges));

    getConsultantMetrics(
      { ...lastYearRange, ...currYearRanges },
      { deduplicate: "newest", events: [EventType.isfpStatusQuoRevenueEstimate, EventType.onePagerRevenueEstimate] },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLabels(currYearMonths);

    let currYearAccSum = 0;
    const counts = currYearMonths.map((label) => {
      currYearAccSum += getRevenueEstimate(metrics?.timeRangeSum[label]);
      return currYearAccSum;
    });

    const lastYearEstimatedRevenue = getRevenueEstimate(metrics?.timeRangeSum[`${new Date().getFullYear() - 1}`]);
    const percentageChange = lastYearEstimatedRevenue ? Math.round((currYearAccSum * 100) / lastYearEstimatedRevenue) - 100 : undefined;

    setPercentageChange(percentageChange);
    setCurrentYearEstimatedRevenue(currYearAccSum);
    setData(counts);
  }, [currYearMonths, isLoading, metrics]);

  if (isLoading) {
    return (
      <Box sx={{ flexGrow: 1, bgcolor: "background.default", py: 2, px: 4, height: "100%" }}>
        <Grid container xs={12}>
          <Grid flexGrow={1}>
            <Skeleton animation='wave' width={200} />
            <Skeleton animation='wave' width={200} variant='rectangular' height={40} />
            <Skeleton animation='wave' width={200} />
          </Grid>
          <Grid xs={1} my={"auto"}>
            <Skeleton animation='wave' variant='circular' width={30} height={30} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "background.default", py: 2, px: 4, height: "100%" }}>
      <Grid container xs={12}>
        <Grid flexGrow={1}>
          <Tooltip title={"Ihr Finanzierungsvolumen auf Basis von Abschlusspotential Ihrer Kunden"}>
            <Typography variant='caption' sx={{ color: "#acacac" }}>
              Dynamische Umsatzprognose <InfoIcon fontSize='inherit' sx={{ verticalAlign: "middle" }} />
            </Typography>
          </Tooltip>
          <Typography variant='h4'>
            {formatNum(currentYearEstimatedRevenue)}
            {" €"}
          </Typography>
          {percentageChange !== undefined && (
            <Box sx={{ width: "100%" }}>
              {percentageChange >= 0 && <ArrowUpwardIcon sx={{ fontSize: 24, fontWeight: 800, color: "success.light" }} />}
              {percentageChange < 0 && <ArrowDownwardIcon sx={{ fontSize: 24, fontWeight: 800, color: "error.light" }} />}
              <Typography variant='caption' sx={{ fontWeight: 800, color: percentageChange >= 0 ? "success.light" : "error.light", verticalAlign: "super" }}>
                {percentageChange > 0 ? "+" : percentageChange < 0 ? "-" : ""}
                {percentageChange}
                {"%"}
              </Typography>
              <Typography variant='caption' sx={{ verticalAlign: "super" }}>
                {" für " + new Date().getFullYear()}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid xs={1} my={"auto"}>
          <IconButton data-cy={"expand-revenue-estimate-btn"} aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Grid>
        <Grid xs={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <LineChart
              xAxis={[{ scaleType: "point", data: labels }]}
              series={[{ data }]}
              height={300}
              margin={{ left: 60, right: 30, top: 30, bottom: 30 }}
              colors={[theme.palette.primary.main]}
              grid={{ horizontal: true }}
              sx={{ [`& .${chartsGridClasses.line}`]: { strokeDasharray: "3 6", strokeWidth: 1 } }}
            />
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
}

const generateMonthRangesUntilNow = () => {
  const months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];
  const params: MetricRangeParams = {};

  const today = new Date();

  for (let month = 0; month <= today.getMonth(); month++) {
    const from = new Date(`${today.getFullYear()}-${month + 1}-01`).valueOf();
    const to = new Date(`${today.getFullYear()}-${month + 2}-01`).valueOf() - 1;
    params[`${months[month]}`] = {
      from,
      to,
    };
  }

  return params;
};

const generatePreviousYearRange = (): MetricRangeParams => {
  const today = new Date();
  const currYear = today.getFullYear();
  return {
    [`${currYear - 1}`]: {
      from: new Date(`${currYear - 1}-01-01`).valueOf(),
      to: new Date(`${currYear}-01-01`).valueOf() - 1,
    },
  };
};
