import Box from "@mui/material/Box";
import useImage from "../../../../hooks/useImage";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { Steps } from "../../../../types/cockpit/types";

export default function Sophia({ step }: { step: Steps }) {
  const bubble = useImage("bubble.svg");
  const sophia = useImage("sophia-avatar.png");
  return (
    <Stack alignContent={"center"} maxWidth={"288px"} mx={"auto"}>
      <Box height={"302px"}>
        <img src={bubble.image} style={{ position: "absolute" }} />
        <Typography
          fontSize={11}
          fontWeight={700}
          sx={{
            position: "relative",
            top: 30,
            left: 20,
          }}
        >
          Warum benötigen wir diese Daten?
        </Typography>
        {getContent(step)}
      </Box>
      <Box
        sx={{
          backgroundImage: `url('${sophia.image}')`,
          backgroundSize: "cover",
          borderRadius: "50%",
          alignSelf: "flex-end",
          width: 48,
          height: 48,
        }}
      />
    </Stack>
  );
}

function getContent(step: Steps) {
  switch (step) {
    case Steps.start:
      return (
        <Typography
          fontSize={11}
          fontWeight={400}
          sx={{
            position: "relative",
            top: 50,
            left: 20,
          }}
        >
          Je nach Alter des Hauses wurde nach
          <br />
          unterschiedlichen Bauvorschriften gebaut.
          <br />
          Zum Beispiel mit oder ohne
          <br />
          Wärmedämmung. Dies beeinflusst die
          <br />
          Energieeffizienz.
          <br />
          <br />
          Auch der Haustyp hat einen
          <br />
          entscheidenden Einfluss auf
          <br />
          Wärmeverluste und sinnvolle
          <br />
          Empfehlungen zur Modernisierung.
        </Typography>
      );
    case Steps.heating:
      return (
        <Typography
          fontSize={11}
          fontWeight={400}
          sx={{
            position: "relative",
            top: 50,
            left: 20,
          }}
        >
          Für Heizungen ist zurzeit viel Förderung
          <br />
          verfügbar. Außerdem besteht für ältere
          <br />
          Heizanlagen zum Teil eine
          <br />
          Austausch-Verpflichtung.
          <br />
          <br />
          Wir helfen Ihnen herauszufinden, ob es
          <br />
          sich lohnt, die Effizienz Ihrer Heizung
          <br />
          zu steigern oder diese sogar zu erneuern.
        </Typography>
      );
    case Steps.insulation:
      return (
        <Typography
          fontSize={11}
          fontWeight={400}
          sx={{
            position: "relative",
            top: 50,
            left: 20,
          }}
        >
          Dach, Wände und Boden bilden
          <br />
          zusammen eine sehr große Fläche,
          <br />
          über die die meiste Wärme verloren geht.
          <br />
          Wenn sie gut isoliert sind, genügt eine
          <br />
          kleinere Heizung.
          <br />
          <br />
          Weiterer Vorteil: Nie wieder kalte Füße!
          <br />
          Je weniger Wärme hinaus kann,
          <br />
          desto behaglicher ist das Haus.
        </Typography>
      );
    case Steps.openings:
      return (
        <Typography
          fontSize={11}
          fontWeight={400}
          sx={{
            position: "relative",
            top: 50,
            left: 20,
          }}
        >
          Auch über Fenster und Türen kann viel
          <br />
          Wärme entweichen. Je besser die
          <br />
          Verglasung und je moderner die Haustür,
          <br />
          desto besser ist in der Regel
          <br />
          die Energieeffizienz des Hauses.
        </Typography>
      );
    default:
      return null;
  }
}
