import { Box, CssBaseline, AppBar, Toolbar, Stack, Grid } from "@mui/material";
import NovoLogo from "../../components/common/NovoLogo";
import signinsignup from "../../imgs/signin_signup_bg.png";
import React, { ReactNode } from "react";

export type AuthContainerProps = {
  children: ReactNode;
};

const AuthContainer: React.FC<AuthContainerProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#f6f6f6",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      <AppBar position='sticky' sx={{ backgroundColor: "background.default", top: 0 }}>
        <Toolbar>
          <NovoLogo sx={{ flexGrow: 1 }} />
          <Stack direction='row' alignItems='center' spacing={2} />
        </Toolbar>
      </AppBar>
      <Box sx={{ flex: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>
        <Grid container>
          <Grid flexGrow={1} />
          <Grid>
            <Grid container>
              <Grid sx={{ backgroundColor: "#fff", padding: 8, minHeight: 600, minWidth: 700 }}>{children}</Grid>
              <Grid
                width={{
                  sm: 0,
                  md: 200,
                  lg: 500,
                  xl: 600,
                }}
                style={{
                  backgroundImage: `url(${signinsignup})`,
                  backgroundSize: "cover",
                }}
              />
            </Grid>
          </Grid>
          <Grid flexGrow={1} />
        </Grid>
      </Box>
    </Box>
  );
};

export default AuthContainer;
