import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Link, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Sophia from "./Sophia";
import ProgressBar from "./ProgressBar";
import { ScreenProps } from "./SchnellcheckFunnel";
import useImage from "../../../../hooks/useImage";
import NovoSelect from "../../../../components/common/NovoSelect";
import { DoorType, WindowType } from "../../../../types/Schnellcheck";
import { allDoorTypes, allWindowTypes, fromDoorLabel, fromWindowLabel, getDoorLabel, getWindowLabel } from "./utils";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import { Steps } from "../../../../types/cockpit/types";
import usePutMetric from "../../../../hooks/usePutMetric";

export default function Screen4({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, saveAndNext }: ScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [putMetric] = usePutMetric();

  useEffect(() => {
    putMetric("Schnellcheck", "RenderOpeningsPage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const conditionalRendering = () => {
    if (isMobile) {
      return;
    }
    return (
      <>
        <Grid sx={{ px: 0 }}>
          <Divider orientation='vertical' />
        </Grid>
        <Grid sx={{ flexGrow: 1 }}>
          <Sophia step={Steps.openings} />
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        backgroundColor: "#f6f6f6",
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid xs={12}>
        <ProgressBar label={"60% geschafft"} value={60} />
      </Grid>
      <Grid xs={12} lg={8}>
        <Form schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={isUpdateRunning} prev={prev} saveAndNext={saveAndNext} />
      </Grid>
      {conditionalRendering()}
    </Grid>
  );
}

function Form({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, saveAndNext }: ScreenProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const window = useImage("isfp-icons/window.png");
  const door = useImage("isfp-icons/door.png");
  const [open, setOpen] = useState(false);
  const [doorError, setDoorError] = useState<string>();
  const [windowError, setWindowError] = useState<string>();

  const updateDoorType = (value: string) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseOpenings: {
          ...schnellcheck.answers?.houseOpenings,
          doors: fromDoorLabel(value),
        },
      },
    });
    setDoorError(undefined);
  };

  const getDoorTypeOptions = (): string[] => allDoorTypes().map((doorType) => getDoorLabel(doorType));

  const updateWindowType = (value: string) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseOpenings: {
          ...schnellcheck.answers?.houseOpenings,
          windows: fromWindowLabel(value),
        },
      },
    });
    setWindowError(undefined);
  };

  const getWindowTypeOptions = (): string[] => allWindowTypes().map((windowType) => getWindowLabel(windowType));

  const validate = () => {
    const { windows, doors } = schnellcheck.answers?.houseOpenings ?? {};
    if (!windows) {
      setWindowError("Pflichtfeld");
      return false;
    } else {
      setWindowError(undefined);
    }

    if (!doors) {
      setDoorError("Pflichtfeld");
      return false;
    } else {
      setDoorError(undefined);
    }
    return true;
  };

  const renderDialogLink = () => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    if (isDesktop) {
      return;
    }
    return (
      <Grid xs={12} lg={0} sx={{ mt: 2 }}>
        <Link
          variant='caption'
          onClick={toggleOpen}
          sx={{
            color: "text.primary",
            textDecorationColor: "rgba(0, 0, 0, 0.87)",
            fontWeight: "fontWeightMedium",
          }}
        >
          Warum benötigen wir diese Daten?
        </Link>
        <Dialog open={open} onClose={toggleOpen} fullScreen>
          <DialogTitle>
            <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sophia step={Steps.openings} />
          </DialogContent>
        </Dialog>
      </Grid>
    );
  };

  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
      sx={{
        ml: { lg: 4 },
        px: { xs: 2 },
        mx: { xs: 0, lg: "inherit" },
      }}
    >
      <Grid xs={12} sx={{ mb: 4 }}>
        <Typography variant='body1'>
          <strong>Schritt 4: </strong>Nun benötigen wir bitte noch ein paar Angaben zu Fenstern und Türen
        </Typography>
      </Grid>
      <Grid xs={12} md={6}>
        <Stack component={Paper} direction={"row"} alignItems='center' sx={{ p: 2 }}>
          <Stack alignItems={"start"} spacing={1}>
            <img src={window.image} height={60} width={60} style={{ marginLeft: "auto", marginRight: "auto" }} />
            <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
              Fenster
            </Typography>
          </Stack>
          <NovoSelect
            label={"Art des Fensters"}
            options={getWindowTypeOptions()}
            dataCy={{ field: "window-type", values: Object.keys(WindowType) }}
            value={getWindowLabel(schnellcheck.answers?.houseOpenings?.windows)}
            onUpdate={updateWindowType}
            required
            error={!!windowError}
            helperText={windowError}
            sx={{
              flexGrow: 1,
              mx: 2,
              "& > label.Mui-focused": {
                color: "text.primary",
              },
              "& > div": {
                color: "text.primary",
                backgroundColor: "rgba(255, 255, 255, 0.10)",
              },
              "& > .MuiOutlinedInput-root.Mui-focused > fieldset": {
                color: "text.primary",
                borderColor: "rgba(0 ,0, 0, 0.87)",
              },
            }}
          />
        </Stack>
      </Grid>
      <Grid xs={12} md={6}>
        <Stack component={Paper} direction={"row"} alignItems='center' sx={{ p: 2 }}>
          <Stack alignItems={"start"} spacing={1}>
            <img src={door.image} height={60} width={60} style={{ marginLeft: "auto", marginRight: "auto" }} />
            <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
              Tür
            </Typography>
          </Stack>
          <NovoSelect
            label={"Art der Tür"}
            options={getDoorTypeOptions()}
            dataCy={{ field: "door-type", values: Object.keys(DoorType) }}
            value={getDoorLabel(schnellcheck.answers?.houseOpenings?.doors)}
            onUpdate={updateDoorType}
            required
            error={!!doorError}
            helperText={doorError}
            sx={{
              flexGrow: 1,
              mx: 2,
              "& > label.Mui-focused": {
                color: "text.primary",
              },
              "& > div": {
                color: "text.primary",
                backgroundColor: "rgba(255, 255, 255, 0.10)",
              },
              "& > .MuiOutlinedInput-root.Mui-focused > fieldset": {
                color: "text.primary",
                borderColor: "rgba(0 ,0, 0, 0.87)",
              },
            }}
          />
        </Stack>
      </Grid>
      {renderDialogLink()}
      <Grid xs={12} sx={{ mt: { xs: 2, lg: 8 } }}>
        <Stack direction={"row"} alignItems={"flex-start"}>
          <Button variant='text' color='secondary' onClick={prev}>
            ZURÜCK
          </Button>
          <Box sx={{ flexGrow: 1 }}>
            <ButtonWithSpinner
              variant='contained'
              sx={{ float: "inline-end" }}
              loading={isUpdateRunning}
              data-cy='screen-4-next-btn'
              onClick={() => {
                if (validate()) {
                  saveAndNext();
                }
              }}
              label={"WEITER"}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}
