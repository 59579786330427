import Dialog from "@mui/material/Dialog";
import { BubbleFlow } from "../../../../types/cockpit/types";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { Status } from "./Status";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface DisplayBubbleFlowSchnellcheckDialogProps {
  bubbleFlow: BubbleFlow;
  open: boolean;
  handleClose: () => void;
}

export function DisplayBubbleFlowSchnellcheckDialog({ bubbleFlow, open, handleClose }: DisplayBubbleFlowSchnellcheckDialogProps) {
  if (!bubbleFlow) return null;
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ p: 2, backgroundColor: "#f6f6f6" }}>
        <DialogTitle>
          iSFP Schnellchek status & antworten
          <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Status {...bubbleFlow} />
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Wofür interessierst du dich am meisten?
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.interest || "--"}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Im welchem Jahr wurde dein haus gebaut?
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.buildYear}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Um welche Art Haus geht es?
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.houseType}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Womit heizt du?
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.heatingType}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Wann wurde deine Heizanlage installiert?
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.heatingSystemAge}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Bitte gib&apos; uns ein paar Infos zu Umfang und Zeitpunkt bereits durchgeführter Sanierungsmaßnahmen. Starten wir mit den Fenstern.
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.windowRenovation}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Wurden der Spitzboden oder die Decke des oberen bewohnten Geschosses gedämmt?
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.cellingRenovation}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Wurden die Außenwände gedämmt?
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.outerWallInsulation}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Gibt es eine Dämmung der Kellerdecke bzw. des Bodens im Erdgeschoss?
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.floorInsulation}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Wurden die Türen oder auch nur die Haustür schon einmal getauscht?
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.doorRenovation}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Fast geschafft! Bitte gib uns noch deine Adresse an:
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                {bubbleFlow.statusQuoResponse?.address}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
