export enum Purpose {
  "Modernisierung/Erweiterung" = "Modernisierung-Erweiterung",
  "Vermietung/Verkauf" = "Vermietung-Verkauf",
  "Aushangpflicht" = "Aushangpflicht",
  "Sonstiges" = "Sonstiges",
}
const isPurpose = (maybePurpose: string): maybePurpose is keyof typeof Purpose => {
  return Object.keys(Purpose).includes(maybePurpose);
};
export const getPurpose = (key: string) => {
  const purpose: string | undefined = isPurpose(key) ? Purpose[key] : undefined;
  return purpose;
};

export enum BuildingType {
  "Einfamilienhaus" = "Einfamilienhaus",
  "Zweifamilienhaus" = "Zweifamilienhaus",
  "Mehrfamilienhaus" = "Mehrfamilienhaus",
  "Wohnteil gemischt genutztes Gebäude" = "Wohnteil gemischt genutztes Gebäude",
  "Reihenmittelhaus" = "Beidseitig angebautes Gebäude",
  "Reihenendhaus/Doppelhaushälfte" = "Einseitig angebautes Gebäude",
  "Sonstiges" = "Sonstiges",
}
const isBuildingType = (maybeBuildingType: string): maybeBuildingType is keyof typeof BuildingType => {
  return Object.keys(BuildingType).includes(maybeBuildingType);
};
export const getBuildingType = (key: string) => {
  const buildingType: string | undefined = isBuildingType(key) ? BuildingType[key] : undefined;
  return buildingType;
};

export enum Partition {
  "Gesamtgebäude" = "Ganzes Gebäude",
  "Teil des Nichtwohngebäudes" = "Teil des Nichtwohngebäudes",
}
const isPartition = (maybePartition: string): maybePartition is keyof typeof Partition => {
  return Object.keys(Partition).includes(maybePartition);
};
export const getPartition = (key: string) => {
  const partition: string | undefined = isPartition(key) ? Partition[key] : undefined;
  return partition;
};

export enum GermanState {
  "Baden-Württemberg" = "Baden-Württemberg",
  "Bayern" = "Bayern",
  "Berlin" = "Berlin",
  "Brandenburg" = "Brandenburg",
  "Bremen" = "Bremen",
  "Hamburg" = "Hamburg",
  "Hessen" = "Hessen",
  "Mecklenburg-Vorpommern" = "Mecklenburg-Vorpommern",
  "Niedersachsen" = "Niedersachsen",
  "Nordrhein-Westfalen" = "Nordrhein-Westfalen",
  "Rheinland-Pfalz" = "Rheinland-Pfalz",
  "Saarland" = "Saarland",
  "Sachsen" = "Sachsen",
  "Sachsen-Anhalt" = "Sachsen-Anhalt",
  "Schleswig-Holstein" = "Schleswi-Holstein",
  "Thüringen" = "Thüringen",
}
const isGermanState = (maybeGermanState: string): maybeGermanState is keyof typeof GermanState => {
  return Object.keys(GermanState).includes(maybeGermanState);
};
export const getGermanState = (key: string) => {
  const germanState: string | undefined = isGermanState(key) ? GermanState[key] : undefined;
  return germanState;
};

export enum Reforms {
  "Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten" = "Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten",
  "Ich weiß es nicht" = "Ich weiß es nicht",
  "Nein" = "Nein",
}

export enum Month {
  "Januar" = "01",
  "Februar" = "02",
  "März" = "03",
  "April" = "04",
  "Mai" = "05",
  "Juni" = "06",
  "Juli" = "07",
  "August" = "08",
  "September" = "09",
  "Oktober" = "10",
  "November" = "11",
  "Dezember" = "12",
}
const isMonth = (maybeMonth: string): maybeMonth is keyof typeof Month => {
  return Object.keys(Month).includes(maybeMonth);
};
export const getMonth = (key: string) => {
  const month: string | undefined = isMonth(key) ? Month[key] : undefined;
  return month;
};
