import Stack from "@mui/material/Stack";
import { RowProps } from "../LeadList";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { IsfpState, Product } from "../../../../../types/cockpit/types";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Button from "@mui/material/Button";
import ProgressDial from "./ProgressDial";
import Link from "@mui/material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import { DisplayBubbleFlowSchnellcheckDialog } from "../DisplayBubbleFlowSchnellcheckDialog";
import { useState } from "react";
import { DisplayBubbleFlowRenovationDialog } from "../DisplayBubbleFlowRenovationDialog";
import { DisplaySchnellcheckDialog } from "../DisplaySchnellcheckDialog";
import { DisplayEmbeddedDialog } from "../DisplayEmbeddedDialog";
import { SchnellcheckSource } from "../../../../../types/Schnellcheck";

export default function IsfpWorkflow({ row, openInviteLeadModal }: { row: RowProps; openInviteLeadModal: (product: Product) => void }) {
  const renderStates = () => {
    return (
      <Stack direction={"row"} alignItems={"center"} alignSelf={"flex-end"}>
        <Stack>
          <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Aktueller Status</Typography>
          <Chip label={IsfpState.toString(row.isfp.state)} />
        </Stack>
        {row.isfp.state !== IsfpState.FINAL && <NavigateNextIcon sx={{ alignSelf: "flex-end", mb: "0.25rem" }} />}
        {row.isfp.state !== IsfpState.FINAL && (
          <Stack>
            <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Nächster Schritt</Typography>
            <Chip label={IsfpState.toString(row.isfp.state + 1)} />
          </Stack>
        )}
      </Stack>
    );
  };
  if (row.isfp.state === IsfpState.START) {
    return (
      <Stack direction={"row"} spacing={5} alignItems={"flex-end"}>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: "fontWeightBold",
            width: "8rem",
            alignSelf: "center",
          }}
        >
          iSFP
        </Typography>
        <Button
          variant='contained'
          sx={{
            fontSize: "0.7rem",
          }}
          onClick={() => openInviteLeadModal(Product.isfp)}
        >
          {IsfpState.toString(row.isfp.state)}
        </Button>
        <Typography
          sx={{
            fontSize: "0.7rem",
            color: "text.secondary",
          }}
        >
          Nachdem der Schnellcheck durchgeführt wurde, geht es weiter zum Angebot für einen iSFP.
        </Typography>
      </Stack>
    );
  } else {
    return (
      <Stack direction={"row"} spacing={5} alignItems={"flex-end"}>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: "fontWeightBold",
            width: "8rem",
            alignSelf: "baseline",
          }}
        >
          iSFP
        </Typography>
        <ProgressDial progress={(100 * row.isfp.state) / (IsfpState.all().length - 1)} />
        {renderStates()}
        <IsfpAction row={row} />
      </Stack>
    );
  }
}

function IsfpAction({ row }: { row: RowProps }) {
  const [isSchnellcheckOpen, setSchnellcheckOpen] = useState(false);
  const [isRenovationsOpen, setRenovationsOpen] = useState(false);

  const toggleSchnellcheckModal = () => {
    setSchnellcheckOpen(!isSchnellcheckOpen);
  };

  const toggleRenovationsModal = () => {
    setRenovationsOpen(!isRenovationsOpen);
  };

  if ([IsfpState.STATUS_QUO, IsfpState.OFFER_REQUESTED].includes(row.isfp.state)) {
    return (
      <Box>
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
          }}
          onClick={toggleSchnellcheckModal}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
        {row.isfp.bubbleFlow && (
          <DisplayBubbleFlowSchnellcheckDialog bubbleFlow={row.isfp.bubbleFlow} open={isSchnellcheckOpen} handleClose={toggleSchnellcheckModal} />
        )}
        {row.isfp.schnellcheck && row.isfp.schnellcheck.source === SchnellcheckSource.standalone && (
          <DisplaySchnellcheckDialog schnellcheck={row.isfp.schnellcheck} open={isSchnellcheckOpen} handleClose={toggleSchnellcheckModal} />
        )}
        {row.isfp.schnellcheck && row.isfp.schnellcheck.source === SchnellcheckSource.embedded && (
          <DisplayEmbeddedDialog schnellcheck={row.isfp.schnellcheck} open={isSchnellcheckOpen} handleClose={toggleSchnellcheckModal} />
        )}
      </Box>
    );
  } else if ([IsfpState.OFFER_ACCEPTED, IsfpState.RENOVATIONS].includes(row.isfp.state)) {
    return (
      <Box>
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
          }}
          onClick={toggleRenovationsModal}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
        <DisplayBubbleFlowRenovationDialog bubbleFlow={row.isfp.bubbleFlow} open={isRenovationsOpen} handleClose={toggleRenovationsModal} />
      </Box>
    );
  } else if (row.isfp.state === IsfpState.FINAL) {
    return (
      <Link
        href={row.isfp.bubbleCdnUrl}
        underline={"always"}
        sx={{
          color: "text.primary",
          fontSize: "0.9rem",
          textDecorationColor: "inherit",
        }}
      >
        <PictureAsPdfIcon
          sx={{
            fontSize: "1.2rem",
            verticalAlign: "middle",
          }}
        />
        iSFP einsehen
      </Link>
    );
  } else {
    return <></>;
  }
}
