import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

type SpinnerProps = {
  enabled: boolean;
  size: number;
};

export default function Spinner({ enabled, size }: SpinnerProps) {
  if (enabled) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress size={size} />
      </Box>
    );
  }
  return <></>;
}
