import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useEffect } from "react";
import Smartlook from "smartlook-client";
import { Paper } from "@mui/material";
import usePutMetric from "../../hooks/usePutMetric";

export const CONSENT = "CONSENT";

export default function ConsentSnackbar() {
  const [putMetric] = usePutMetric();
  const [open, setOpen] = React.useState(false);

  const handleNoConsent = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    localStorage.setItem(CONSENT, "false");
    setOpen(false);
  };

  const handleConsentGiven = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    localStorage.setItem(CONSENT, "true");
    setOpen(false);
    hasGivenConsent();
    putMetric("ZuGuEmbedded", "ConsentGiven");
  };

  const hasGivenConsent = () => {
    Smartlook.init("0339ef23f1dd0199b8236c6b07baf8bd0ccf8abe", { region: "eu" });
  };

  useEffect(() => {
    // Local Storage
    const consentString = localStorage.getItem(CONSENT);
    if (consentString) {
      setOpen(false);
      if (JSON.parse(consentString)) {
        hasGivenConsent();
      }
    } else {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <Snackbar open={open} anchorOrigin={{ vertical: "top", horizontal: "center" }} style={{ width: "90%", backgroundColor: "#d2d4d6" }}>
          <Paper style={{ padding: 15 }}>
            <Typography style={{ fontSize: "calc(4px + 2vw)" }} color='#7d7c79'>
              Cookie- und Tracking-Zustimmung: Wir respektieren Ihre Privatsphäre. Um Ihre Erfahrung auf unserer Website zu verbessern, möchten wir Cookies und
              Tracking-Technologien verwenden. Diese helfen uns dabei, die Leistung der Website zu optimieren, Ihnen relevante Inhalte anzuzeigen und Ihr
              Erlebnis zu personalisieren. Ihre Privatsphäre ist uns wichtig. Bitte wählen Sie, wie Sie mit Cookies und Tracking umgehen möchten.{" "}
              <Link target='_blank' href='https://novo.eco/privacy'>
                Datenschutzerklärung
              </Link>
            </Typography>
            <Button size='small' onClick={handleConsentGiven} data-cy='accept-cookies-btn'>
              Alle akzeptieren
            </Button>
            <Button size='small' onClick={handleNoConsent}>
              Ablehnen
            </Button>
          </Paper>
        </Snackbar>
      </Backdrop>
    </>
  );
}
