import { useCallback } from "react";
import { apiEndpoint } from "../utils/utils";
import { useAuthAxios } from "./useAuthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/consumption-certificate`;

export const useGetConsumptionCertPdfLink = <ReturnType>() => {
  const { authAxios, error: getDocumentError, running: getDocumentRunning, data: getDocumentData } = useAuthAxios<ReturnType>();

  const getConsumptionCertLink = useCallback(
    async (consumptionCertId: string) =>
      await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}/${consumptionCertId}/document`,
      }),
    [authAxios],
  );

  return { getConsumptionCertLink, getDocumentError, getDocumentRunning, getDocumentData };
};
