import Button, { ButtonOwnProps } from "@mui/material/Button";
import Spinner from "./Spinner";

type ButtonWithSpinnerProps = {
  loading?: boolean;
  label: string;
  "data-cy"?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & ButtonOwnProps;

export default function ButtonWithSpinner(args: ButtonWithSpinnerProps) {
  const { loading, ...params } = args;

  if (loading) {
    return (
      <Button {...params} disabled={loading || params.disabled}>
        <Spinner enabled={loading} size={20} />
      </Button>
    );
  }
  return (
    <Button {...params} disabled={loading || params.disabled}>
      {params.label}
    </Button>
  );
}
