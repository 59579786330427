import { useCallback } from "react";
import { useUnauthAxios } from "./useUnauthAxios";
import { apiEndpoint } from "../utils/utils";
import { MailingSettings } from "./useGetMailingSettings";

export const usePutMailingSettings = () => {
  const { unauthAxios, error, running, data } = useUnauthAxios<MailingSettings>();

  const putMailingSettings = useCallback(
    async (leadId: string, payload: MailingSettings) => {
      await unauthAxios({
        method: "PUT",
        url: `${apiEndpoint()}/mailing-preferences?leadId=${leadId}`,
        data: payload,
      });
    },
    [unauthAxios],
  );

  return { putMailingSettings, error, running, data };
};
