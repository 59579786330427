import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useFetchConsultants } from "../../../hooks";
import { ConsultantWithMetrics } from "../../../types/cockpit/types";
import { ConsultantTable } from "./ConsultantsTable";

export function ConsultantsList() {
  const { getConsultants, consultants, isLoading, error } = useFetchConsultants();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [filteredConsultants, setFilteredConsultants] = useState<Array<ConsultantWithMetrics>>();

  useEffect(() => {
    getConsultants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.SyntheticEvent, value: string) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    const searchFilter = (consultant: ConsultantWithMetrics) => {
      if (!searchQuery) {
        return true;
      }
      const normalisedQuery = searchQuery.toLowerCase();
      return (
        consultant.name?.toLowerCase().includes(normalisedQuery) ||
        consultant.email?.toLowerCase().includes(normalisedQuery) ||
        consultant.phoneNumber?.toLowerCase().includes(normalisedQuery)
      );
    };
    setFilteredConsultants(consultants?.filter(searchFilter));
  }, [consultants, searchQuery]);

  const handleRefresh = () => {
    getConsultants();
  };

  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4, mx: 2 }}>
      <Stack spacing={2}>
        <TableTopActions onSearchChange={handleSearch} onRefresh={handleRefresh} />
        <ConsultantTable isLoading={isLoading} error={error} consultants={filteredConsultants} />
      </Stack>
    </Box>
  );
}

type TableTopActionsProps = {
  onSearchChange: (event: React.SyntheticEvent, value: string) => void;
  onRefresh: () => void;
};
function TableTopActions({ onSearchChange, onRefresh }: TableTopActionsProps) {
  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <Autocomplete
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label='Suchen'
              placeholder='Name oder E-mail'
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          options={[]}
          onChange={onSearchChange}
          sx={{ flexGrow: 4, bgcolor: "background.default" }}
        />
        <Button data-cy='refresh-lead-list-btn' variant='outlined' color='secondary' sx={{ fontSize: 12 }} onClick={onRefresh}>
          <RefreshIcon />
        </Button>
      </Stack>
    </Box>
  );
}
