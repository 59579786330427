import { useCallback } from "react";
import { useUnauthAxios } from "./useUnauthAxios";
import { apiEndpoint } from "../utils/utils";

export type MailingSettings = {
  email: string;
  // (consumption certs, schnellchecks, etc...)
  acceptOffers?: boolean;
};

export const useGetMailingSettings = () => {
  const { unauthAxios, error, running, data } = useUnauthAxios<MailingSettings>();

  const getMailingSettings = useCallback(
    async (leadId: string) => {
      await unauthAxios({
        method: "GET",
        url: `${apiEndpoint()}/mailing-preferences?leadId=${leadId}`,
      });
    },
    [unauthAxios],
  );

  return { getMailingSettings, error, running, data };
};
