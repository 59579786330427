import Autocomplete from "@mui/material/Autocomplete";
import { BoxProps } from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState } from "react";

interface NovoMultiSelectProps extends BoxProps {
  label: string;
  name?: string;
  options: Array<string>;
  value: Array<string>;
  disabled?: boolean;
  onUpdate?: (value: Array<string>) => void;
}

export default function NovoMultiSelect({ label, options, value, onUpdate, name = label.toLowerCase().replace(" ", "-"), sx, disabled }: NovoMultiSelectProps) {
  const [_value, setValue] = useState(value || []);
  const handler = (event: React.SyntheticEvent, value: Array<string>) => {
    setValue(value);
    if (onUpdate) {
      onUpdate(value);
    }
  };

  return (
    <Autocomplete
      multiple
      id={`${name}-tags-outlined`}
      options={options}
      getOptionLabel={(option) => option}
      value={_value}
      filterSelectedOptions
      onChange={handler}
      renderInput={(params) => <TextField {...params} label={label} />}
      sx={sx}
      disabled={disabled}
    />
  );
}
