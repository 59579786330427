/* eslint-disable @typescript-eslint/no-unused-vars */
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { ModernisationRecommendation } from "./ModernisationRecommendation";
import { useFetchConsumptionCertificate } from "../../../hooks/useFetchConsumptionCertificates";
import { useUpdateConsumptionCertModernisations } from "../../../hooks/useUpdateConsumptionCertificateModifications";
import { ModernisationRecommendationState } from "../../../types/ConsumptionCertificateState";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ConsumptionCertificateCard from "./ConsumptionCertificateCard";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

type ModernisationListEntryItem = {
  record: ModernisationRecommendationState;
  isNew: boolean;
  key: string;
};

export default function ConsumptionCertificateModernisation() {
  // get certificate id from url route
  const { certificateId } = useParams();
  if (!certificateId) {
    throw new Error("unexpected empty certificateId route parameter");
  }

  const { consumptionCert, isLoading } = useFetchConsumptionCertificate(certificateId);
  const [modernisations, setModernisations] = useState<ModernisationListEntryItem[]>([]);
  const [deleteItemOpenDialog, setDeleteItemOpenDialog] = useState(false);
  const [deleteModernisationItem, setDeleteModernisationItem] = useState<{ key?: string; modernisation?: ModernisationRecommendationState }>({});
  const [openCreateItemDialog, setOpenCreateItemDialog] = useState(false);
  const [createModernisationItem, setCreateModernisationItem] = useState<ModernisationListEntryItem>();
  const { putModernisations } = useUpdateConsumptionCertModernisations(certificateId);

  useEffect(() => {
    if (!isLoading && consumptionCert?.answers.modernisationRecommendations) {
      setModernisations(
        consumptionCert?.answers?.modernisationRecommendations?.map((recommendation) => ({
          key: v4().toString(),
          record: recommendation,
          isNew: false,
        })),
      );
    }
  }, [isLoading, consumptionCert]);

  const putReadyModernisations = (modernisations: ModernisationListEntryItem[]) => {
    // persist only the ones that are not marked as new (and thus not yet ready to be inserted)
    let toPersist: ModernisationRecommendationState[] = [];
    let idx = 1;
    for (const modernisation of modernisations) {
      if (!modernisation.isNew) {
        toPersist = [...toPersist, { ...modernisation.record, number: idx++ }];
      }
    }
    putModernisations(toPersist);
  };

  const onModernisationChange = (id: string) => {
    return (newValue: ModernisationRecommendationState) => {
      const newModernisationArr = modernisations.map((oldValue) => (oldValue.key === id ? { ...oldValue, record: newValue, isNew: false } : oldValue));
      setModernisations(newModernisationArr);

      putReadyModernisations(newModernisationArr);
    };
  };

  const onCancel = (key: string) => {
    return (_: ModernisationRecommendationState) => {
      const newModernisations = modernisations
        .filter((modernisation) => !modernisation.isNew || (modernisation.isNew && modernisation.key !== key))
        // if something was deleted, reorder numbers
        .map((item, idx) => ({ ...item, record: { ...item.record, number: idx + 1 } }));

      setModernisations(newModernisations);
    };
  };

  const onAddModernisation = () => {
    setCreateModernisationItem({ isNew: true, record: { number: modernisations.length + 1 }, key: v4().toString() });
    setOpenCreateItemDialog(true);
  };

  const handleCreateModernisation = (newValue: ModernisationRecommendationState) => {
    if (!createModernisationItem) {
      return;
    }
    createModernisationItem.record = newValue;
    createModernisationItem.isNew = false;

    const newModernisationArr = [...modernisations, createModernisationItem];
    setModernisations(newModernisationArr);
    putReadyModernisations(newModernisationArr);
    setOpenCreateItemDialog(false);
  };

  const handleCancelCreateModernsation = () => {
    setOpenCreateItemDialog(false);
  };

  const onDelete = (key: string) => {
    // this entry comes from the ModernisationRecommendation component delete button click
    return (modernisation: ModernisationRecommendationState) => {
      setDeleteModernisationItem({ key, modernisation });
      setDeleteItemOpenDialog(true);
    };
  };

  const handleDeleteCancel = () => {
    setDeleteItemOpenDialog(false);
  };

  const handleDeleteConfirmClick = () => {
    if (deleteModernisationItem.key) {
      // delete logic
      const newModernisations = modernisations
        .filter((mod) => mod.key !== deleteModernisationItem.key)
        .map((item, idx) => ({ ...item, record: { ...item.record, number: idx + 1 } }));

      setModernisations(newModernisations);
      putReadyModernisations(newModernisations);
    }
    setDeleteItemOpenDialog(false);
  };

  if (isLoading) {
    return (
      <Grid container sx={{ width: "100%", minHeight: "100vh", alignItems: "center" }}>
        <Grid xs={12} textAlign={"center"}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
  return (
    <Box sx={{ flexGrow: 1, mx: 5 }}>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 2,
          maxWidth: "lg",
          mx: "auto",
        }}
      >
        <Grid xs={12}>
          <Typography variant='h4'>Modernisierungsempfehlungen</Typography>
        </Grid>
        <Grid xs={12}>
          <ConsumptionCertificateCard cert={consumptionCert} />
        </Grid>
        {modernisations?.length > 0 &&
          modernisations.map((modernisation) => (
            <Grid xs={4} key={modernisation.record.number}>
              <ModernisationRecommendation
                startInEditMode={modernisation.isNew}
                modernisation={modernisation.record}
                onChange={onModernisationChange(modernisation.key)}
                onCancel={onCancel(modernisation.key)}
                onDelete={onDelete(modernisation.key)}
              />
            </Grid>
          ))}
      </Grid>
      <Fab aria-label='add-recommendation' sx={{ position: "fixed", bottom: 40, right: 40 }} color='primary' onClick={onAddModernisation}>
        <AddIcon />
      </Fab>
      {createModernisationItem && (
        <Dialog
          open={openCreateItemDialog}
          onClose={handleCancelCreateModernsation}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{"Neue Modernisierungsempfehlung"}</DialogTitle>
          <DialogContent>
            <ModernisationRecommendation
              startInEditMode={createModernisationItem?.isNew}
              modernisation={createModernisationItem?.record}
              onChange={handleCreateModernisation}
              onCancel={handleCancelCreateModernsation}
            />
          </DialogContent>
        </Dialog>
      )}
      <Dialog open={deleteItemOpenDialog} onClose={handleDeleteCancel} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{`${deleteModernisationItem.modernisation?.number}. ${deleteModernisationItem.modernisation?.componentEquipment} (${deleteModernisationItem.modernisation?.renovationCombination})  löschen?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>Eintrag wird endgültig gelöscht</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Abbrechen</Button>
          <Button variant='outlined' onClick={handleDeleteConfirmClick} autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
