import { Button, Link, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import useImage from "../../../../hooks/useImage";

export function ConfirmationScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { image } = useImage("agent.svg");
  return (
    <Grid
      container
      spacing={0}
      sx={{
        mx: { xs: 0, lg: 10 },
        backgroundColor: "#f6f6f6",
        mb: 4,
      }}
    >
      <Grid xs={12}>
        <Stack
          component={Paper}
          sx={{
            maxWidth: "lg",
            mx: "auto",
            p: 4,
            mt: 8,
            mb: isMobile ? 0 : 8,
            alignItems: "center",
            position: "relative",
            "&::after": {
              content: `url("${image}")`,
              display: "block",
              position: "absolute",
              top: isMobile ? "-10%" : "-3%",
              left: isMobile ? "calc(50% - 68px)" : "5%",
              overflow: "hidden",
            },
          }}
        >
          <Typography data-cy='thank-you-text' fontWeight={"fontWeightMedium"} fontSize={isMobile ? "1.75rem" : "3rem"} sx={{ pt: isMobile ? "100px" : "0" }}>
            Vielen Dank!
          </Typography>
          <Typography mt={2} fontWeight={"fontWeightMedium"} fontSize={isMobile ? "0.8rem" : "1.2rem"} sx={{ maxWidth: isMobile ? "100%" : "60%" }}>
            Wir haben Ihre Daten erfasst und unsere Energieberater werden sich umgehend bei Ihnen melden.
          </Typography>
          <Typography mt={2} mb={4} fontSize={isMobile ? "0.8rem" : "1.2rem"} sx={{ maxWidth: isMobile ? "100%" : "60%" }}>
            Sie möchten sich einen konkreten Termin sichern? Dann buchen Sie sich{" "}
            <Link target='_blank' href='https://calendar.app.google/Nfx7DnxqQck45RS77' style={{ fontWeight: 600, color: "#000", textDecorationColor: "none" }}>
              hier
            </Link>{" "}
            bei einem unserer Energieberater ein.
          </Typography>
          <Stack spacing={2} direction={isMobile ? "column" : "row"}>
            <Tooltip title='unsere Energieberater melden sich aktiv bei Ihnen'>
              <Button data-cy='homepage-btn' id='homepage-btn' variant='contained' sx={{ mt: 4 }} onClick={() => window.location.assign("https://novo.eco/")}>
                zurück zur Hauptseite
              </Button>
            </Tooltip>
            <Tooltip title='Sie bestimmen, wann wir miteinander sprechen'>
              <Button
                data-cy='homepage-btn'
                id='homepage-btn'
                variant='contained'
                sx={{ mt: 4 }}
                onClick={() => window.open("https://calendar.app.google/Nfx7DnxqQck45RS77", "_blank")}
              >
                einen Termin buchen
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
