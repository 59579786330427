import Grid from "@mui/material/Unstable_Grid2";
import { SectionProps } from "./ConsumptionCertificateRequestPage";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import NovoSelect from "../../../components/common/NovoSelect";
import Typography from "@mui/material/Typography";
import { Fragment, useState } from "react";
import { Link, Stack, styled, Tooltip } from "@mui/material";
import { getMonth, Month } from "./ConsumptionCertificateRequestEnums";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { addMonths, differenceInMonths, format, subDays } from "date-fns";

const INFIMUM_FACTOR = 12;
const SUPREMUM_FACTOR = 10 * INFIMUM_FACTOR;

const formatStartDate = (month: string, year: string, variation: number): string => {
  return format(addMonths(new Date(`${parseInt(year) - variation - 1}-${getMonth(month)}-01`), 1), "dd.MM.yyyy");
};

const formatEndDate = (month: string, year: string, variation: number): string => {
  return format(subDays(addMonths(new Date(`${parseInt(year) - variation}-${getMonth(month)}-01`), 1), 1), "dd.MM.yyyy");
};

const WarningIcon = styled(ReportProblemOutlinedIcon)(({ theme }) => ({
  "@keyframes bleep": {
    from: {
      backgroundColor: `rgb(from ${theme.palette.primary.light} r g b / 1.0)`,
    },
    to: {
      backgroundColor: `rgb(from ${theme.palette.primary.light} r g b / 0)`,
    },
  },
  animation: "bleep 1s ease-out",
  padding: 8,
  borderRadius: "100%",
}));

export default function HeatingSection({ data, disabled, updateHandler }: SectionProps) {
  const [selectedMonth, setSelectedMonth] = useState(data.heatingData.readingMonth || "");
  const [selectedYear, setSelectedYear] = useState(data.heatingData.readingYear || "");

  const infimum = data.buildingData.surface ? INFIMUM_FACTOR * +data.buildingData.surface : undefined;
  const supremum = data.buildingData.surface ? SUPREMUM_FACTOR * +data.buildingData.surface : undefined;

  const installationYearHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.heatingData.installationYear = event.target.value;
    updateHandler(data);
  };
  const heatingSystemHandler = (value: string) => {
    data.heatingData.heatingSystem = value;
    updateHandler(data);
  };
  const readingMonthHandler = (value: string) => {
    data.heatingData.readingMonth = value;
    setSelectedMonth(value);
    updateHandler(data);
  };
  const readingYearHandler = (value: string) => {
    data.heatingData.readingYear = value;
    const today = new Date();
    // if current year, update values of selectable months up to the current one, as
    // months in the future make no sense
    if (`${today.getFullYear()}` == value) {
      data.heatingData.months = Object.keys(Month).filter((_, month) => month <= today.getMonth());
      // conversely, only accept bills up to 18 months old
    } else {
      data.heatingData.months = Object.keys(Month).filter((_, month) => differenceInMonths(today, new Date(`${value}/${month + 1}/01`)) <= 18);
    }
    setSelectedYear(value);
    updateHandler(data);
  };
  const readingValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.heatingData.readingValue = event.target.value;
    updateHandler(data);
  };
  const readingValueYearBeforeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.heatingData.readingValueYearBefore = event.target.value;
    updateHandler(data);
  };
  const readingValueTwoYearsBeforeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.heatingData.readingValueTwoYearsBefore = event.target.value;
    updateHandler(data);
  };
  const secondaryInstallationYearHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.heatingData.secondaryYearOfHeatingInstalation = event.target.value;
    updateHandler(data);
  };
  const secondaryHeatingSystemHandler = (value: string) => {
    data.heatingData.secondaryHeatingSystemType = value;
    updateHandler(data);
  };
  const secondaryReadingValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.heatingData.secondaryReadingValue = event.target.value;
    updateHandler(data);
  };
  const secondaryReadingValueYearBeforeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.heatingData.secondaryReadingValueYearBefore = event.target.value;
    updateHandler(data);
  };
  const secondaryReadingValueTwoYearsBeforeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.heatingData.secondaryReadingValueTwoYearsBefore = event.target.value;
    updateHandler(data);
  };

  return (
    <Grid container spacing={2} alignItems={"center"} textAlign={"right"}>
      <Grid xs={12}>
        <NovoSelect
          data-cy='heating-type-select'
          label='Heizsystem'
          value={data.heatingData.heatingSystem}
          options={data.heatingData.heatingSystemOptions}
          required={true}
          error={data.heatingData.heatingSystemError}
          disabled={disabled}
          onUpdate={heatingSystemHandler}
        />
      </Grid>
      <Grid xs={12}>
        <FormControl fullWidth>
          <TextField
            data-cy='installation-year-input'
            variant='outlined'
            id='jahr-installation-input'
            value={data.heatingData.installationYear}
            type='number'
            inputProps={{ min: 0 }}
            label='Jahr der Installation'
            disabled={disabled}
            onChange={installationYearHandler}
            required={true}
            error={data.heatingData.installationYearError}
          />
        </FormControl>
      </Grid>
      <Grid xs={12}>
        <Typography variant='body1' gutterBottom>
          Bitte wählen Sie für das Zertifikat den Endmonat und das Jahr der drei zusammenhängenden Verbrauchsjahre aus. Starten Sie mit der jüngsten Abrechnung.
          <Typography variant='body2' component={"span"}>
            (Das Ende der Abrechnungsperiode darf nicht mehr als 18 Monate zurück liegen, sonst benötigen Sie einen{" "}
            <Link
              href='mailto:hi@buildingnovo.com?subject=Neuen%20Bedarfsausweis%20beantragen&body=Hallo%20NOVO-Team%2C%0A%0Ada%20mir%20nicht%20gen%C3%BCgend%20Verbrauchsdaten%20vorliegen%2C%20m%C3%B6chte%20ich%20einen%20Bedarfsausweis%20anfordern%20und%20ein%20unverbindliches%20Angebot%20einholen.%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen'
              color='#707172'
            >
              Bedarfsausweis
            </Link>
            .)
          </Typography>
        </Typography>
      </Grid>
      <Grid xs={6}>
        <NovoSelect
          data-cy='year-select'
          label='Jahr'
          value={data.heatingData?.readingYear}
          options={data.heatingData.years}
          required={true}
          error={data.heatingData.readingYearError}
          disabled={disabled}
          onUpdate={readingYearHandler}
        />
      </Grid>
      <Grid xs={6}>
        <NovoSelect
          data-cy='month-select'
          label='Monat'
          value={data.heatingData?.readingMonth}
          options={data.heatingData.months}
          required={true}
          error={data.heatingData.readingMonthError}
          disabled={disabled}
          onUpdate={readingMonthHandler}
        />
      </Grid>
      <Grid xs={6}>
        {selectedMonth && selectedYear && (
          <Typography variant='body1'>
            von {formatStartDate(selectedMonth, selectedYear, 0)} bis {formatEndDate(selectedMonth, selectedYear, 0)}:
          </Typography>
        )}
      </Grid>
      <Grid xs={6}>
        <Stack direction='row' alignItems={"center"}>
          <FormControl fullWidth>
            <TextField
              data-cy='consumption-1-input'
              variant='outlined'
              id='verbrauch-1-input'
              value={data.heatingData.readingValue}
              type='number'
              inputProps={{ min: 0 }}
              label='Verbrauch'
              disabled={disabled}
              onChange={readingValueHandler}
              required={true}
              error={data.heatingData.readingValueError}
            />
          </FormControl>
          {!!infimum && !!data.heatingData.readingValue && +data.heatingData.readingValue < infimum && (
            <Tooltip title='Dieser Zählerstand scheint zu niedrig zu sein.' placement='right' arrow>
              <WarningIcon fontSize='large' />
            </Tooltip>
          )}
          {!!supremum && !!data.heatingData.readingValue && +data.heatingData.readingValue > supremum && (
            <Tooltip title='Dieser Zählerstand scheint zu hoch zu sein.' placement='right' arrow>
              <WarningIcon fontSize='large' />
            </Tooltip>
          )}
        </Stack>
      </Grid>
      <Grid xs={6}>
        {selectedMonth && selectedYear && (
          <Typography variant='body1'>
            von {formatStartDate(selectedMonth, selectedYear, 1)} bis {formatEndDate(selectedMonth, selectedYear, 1)}:
          </Typography>
        )}
      </Grid>
      <Grid xs={6}>
        <Stack direction='row' alignItems={"center"}>
          <FormControl fullWidth>
            <TextField
              data-cy='consumption-2-input'
              variant='outlined'
              id='verbrauch-2-input'
              value={data.heatingData.readingValueYearBefore}
              type='number'
              inputProps={{ min: 0 }}
              label='Verbrauch'
              disabled={disabled}
              onChange={readingValueYearBeforeHandler}
              required={true}
              error={data.heatingData.readingValueYearBeforeError}
            />
          </FormControl>
          {!!infimum && !!data.heatingData.readingValueYearBefore && +data.heatingData.readingValueYearBefore < infimum && (
            <Tooltip title='Dieser Zählerstand scheint zu niedrig zu sein.' placement='right' arrow>
              <WarningIcon fontSize='large' />
            </Tooltip>
          )}
          {!!supremum && !!data.heatingData.readingValueYearBefore && +data.heatingData.readingValueYearBefore > supremum && (
            <Tooltip title='Dieser Zählerstand scheint zu hoch zu sein.' placement='right' arrow>
              <WarningIcon fontSize='large' />
            </Tooltip>
          )}
        </Stack>
      </Grid>
      <Grid xs={6}>
        {selectedMonth && selectedYear && (
          <Typography variant='body1'>
            von {formatStartDate(selectedMonth, selectedYear, 2)} bis {formatEndDate(selectedMonth, selectedYear, 2)}:
          </Typography>
        )}
      </Grid>
      <Grid xs={6}>
        <Stack direction='row' alignItems={"center"}>
          <FormControl fullWidth>
            <TextField
              data-cy='consumption-3-input'
              variant='outlined'
              id='verbrauch-3-input'
              value={data.heatingData.readingValueTwoYearsBefore}
              type='number'
              inputProps={{ min: 0 }}
              label='Verbrauch'
              disabled={disabled}
              onChange={readingValueTwoYearsBeforeHandler}
              required={true}
              error={data.heatingData.readingValueTwoYearsBeforeError}
            />
          </FormControl>
          {!!infimum && !!data.heatingData.readingValueTwoYearsBefore && +data.heatingData.readingValueTwoYearsBefore < infimum && (
            <Tooltip title='Dieser Zählerstand scheint zu niedrig zu sein.' placement='right' arrow>
              <WarningIcon fontSize='large' />
            </Tooltip>
          )}
          {!!supremum && !!data.heatingData.readingValueTwoYearsBefore && +data.heatingData.readingValueTwoYearsBefore > supremum && (
            <Tooltip title='Dieser Zählerstand scheint zu hoch zu sein.' placement='right' arrow>
              <WarningIcon fontSize='large' />
            </Tooltip>
          )}
        </Stack>
      </Grid>
      <Grid xs={12}>
        <NovoSelect
          label='Zweites Heizsystem (optional)'
          value={data.heatingData.secondaryHeatingSystemType}
          options={["", ...data.heatingData.heatingSystemOptions]}
          required={false}
          disabled={disabled}
          onUpdate={secondaryHeatingSystemHandler}
        />
      </Grid>
      {data.heatingData.secondaryHeatingSystemType && (
        <Fragment>
          <Grid xs={12}>
            <FormControl fullWidth>
              <TextField
                variant='outlined'
                id='jahr-installation-input-zweites-heizsystem'
                value={data.heatingData.secondaryYearOfHeatingInstalation}
                type='number'
                inputProps={{ min: 0 }}
                label='Jahr der Installation (Zweites Heizsystem)'
                onChange={secondaryInstallationYearHandler}
                disabled={disabled}
                required={true}
                error={data.heatingData.secondaryInstallationYearError}
              />
            </FormControl>
          </Grid>
          <Grid xs={6}>
            {selectedMonth && selectedYear && (
              <Typography variant='body1'>
                von {formatStartDate(selectedMonth, selectedYear, 0)} bis {formatEndDate(selectedMonth, selectedYear, 0)}:
              </Typography>
            )}
          </Grid>
          <Grid xs={6}>
            <FormControl fullWidth>
              <TextField
                variant='outlined'
                id='verbrauch-1-input-zweites-heizsystem'
                value={data.heatingData.secondaryReadingValue}
                type='number'
                inputProps={{ min: 0 }}
                label='Verbrauch (Zweites Heizsystem)'
                onChange={secondaryReadingValueHandler}
                disabled={disabled}
                required={true}
                error={data.heatingData.secondaryReadingValueError}
              />
            </FormControl>
          </Grid>
          <Grid xs={6}>
            {selectedMonth && selectedYear && (
              <Typography variant='body1'>
                von {formatStartDate(selectedMonth, selectedYear, 1)} bis {formatEndDate(selectedMonth, selectedYear, 1)}:
              </Typography>
            )}
          </Grid>
          <Grid xs={6}>
            <FormControl fullWidth>
              <TextField
                variant='outlined'
                id='verbrauch-2-input-zweites-heizsystem'
                value={data.heatingData.secondaryReadingValueYearBefore}
                type='number'
                inputProps={{ min: 0 }}
                label='Verbrauch (Zweites Heizsystem)'
                onChange={secondaryReadingValueYearBeforeHandler}
                disabled={disabled}
                required={true}
                error={data.heatingData.secondaryReadingValueYearBeforeError}
              />
            </FormControl>
          </Grid>
          <Grid xs={6}>
            {selectedMonth && selectedYear && (
              <Typography variant='body1'>
                von {formatStartDate(selectedMonth, selectedYear, 2)} bis {formatEndDate(selectedMonth, selectedYear, 2)}:
              </Typography>
            )}
          </Grid>
          <Grid xs={6}>
            <FormControl fullWidth>
              <TextField
                variant='outlined'
                id='verbrauch-3-input-zweites-heizsystem'
                value={data.heatingData.secondaryReadingValueTwoYearsBefore}
                type='number'
                inputProps={{ min: 0 }}
                label='Verbrauch (Zweites Heizsystem)'
                onChange={secondaryReadingValueTwoYearsBeforeHandler}
                disabled={disabled}
                required={true}
                error={data.heatingData.secondaryReadingValueTwoYearsBeforeError}
              />
            </FormControl>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
}
